import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import FormStatic from '../../common/FormStatic';

import TimeLabel from '../../common/TimeLabel';
import {
  DateOnly_AsString_Raw,
  Date_AsString_NoHtml,
} from '../../../util/formatter';
import { isPowerUser } from '../../auth/authUtils';

import CompanyLink from '../../common/links/CompanyLink';
import UserLink from '../../common/links/UserLink';
import { toast } from 'react-toastify';
import { downloadPurchaseOrder } from '../../../actions/requestActions';
import CollapsePanel from '../../common/CollapsePanel';
import { useTranslation } from 'react-i18next';

function RequestDetails({ request, onReassign, currentUser }) {
  const { t } = useTranslation();
  const handleDownloadPurchaseOrder = () => {
    downloadPurchaseOrder(request.requestGroupId).catch(() => {
      toast.error(t('Failed to download purchase order'));
    });
  };

  // cache the response from this function
  const getColumnCount = useMemo(() => {
    let columnCount = 4;
    let fieldCount = 7;
    if (isPowerUser(currentUser)) fieldCount++;
    if (request.fileOnKeyRequestFilename) fieldCount++;
    if (request.showRequiredByDate) fieldCount++;

    if (fieldCount % 3 === 0) {
      columnCount = 3;
    }
    return `col-count-${columnCount}`;
  }, [
    currentUser,
    request.fileOnKeyRequestFilename,
    request.showRequiredByDate,
  ]);

  return (
    <>
      {request.endUserDetailsRequired &&
        request.numberOfAccountDetails < request.numberOfKeys && (
          <div className="alert alert-warning twinkle flex-shrink-0 ">
            <div>
              {t(
                'This workflow requires account details to be uploaded. You have currently uploaded {numberOfAccountDetails} account details for the {numberOfKeys} keys requested',
                {
                  numberOfAccountDetails: request.numberOfAccountDetails,
                  numberOfKeys: request.numberOfKeys,
                }
              )}
            </div>
          </div>
        )}
      <CollapsePanel
        storageKey={'keyRequestDetails'}
        header={<h2 className="no-margin">{t('Request details')}</h2>}
      >
        <div className={`cols ${getColumnCount}`}>
          <FormStatic label={t('Reference')}>
            {request.groupReference}
          </FormStatic>
          <FormStatic label={t('PO number')}>{request.poNumber}</FormStatic>
          <FormStatic label={t('Requested by')}>
            <UserLink
              id={request.requestedById}
              name={request.requestedByFullName}
            />
            <div className="ms-3">{request.requestedByCompanyName}</div>
          </FormStatic>
          <FormStatic label={t('Company')}>
            <CompanyLink id={request.companyId} name={request.companyName} />
          </FormStatic>
          <FormStatic label={t('Requested')}>
            {Date_AsString_NoHtml(request.requestedDate)}
          </FormStatic>
          <FormStatic label={t('Elapsed time')}>
            <TimeLabel
              elapsedMinutesFormatted={request.elapsedMinutesFormatted}
              breached={request.isSLABreached}
              ongoing={request?.requestCompletionDate ? false : true}
            />
          </FormStatic>
          {request.showRequiredByDate && (
            <FormStatic label={t('Required by')}>
              {DateOnly_AsString_Raw(request.requiredByDate)}
            </FormStatic>
          )}
          <FormStatic label={t('Justification')}>
            {request.justification || t('none')}
          </FormStatic>
          {request.fileOnKeyRequestFilename && (
            <FormStatic label={t('Purchase order')}>
              <span
                className="a"
                onClick={handleDownloadPurchaseOrder}
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    handleDownloadPurchaseOrder();
                  }
                }}
                tabIndex={0}
              >
                {request.fileOnKeyRequestFilename}
              </span>
            </FormStatic>
          )}
        </div>
      </CollapsePanel>
    </>
  );
}

function mapStateToProps(state, ownProps) {
  return {
    currentUser: state.auth,
  };
}

const mapDispatchToProps = {};

RequestDetails.propTypes = {
  request: PropTypes.object.isRequired,
  onReassign: PropTypes.func.isRequired,
  currentUser: PropTypes.object.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(RequestDetails);
