import React, { useState, useRef } from 'react';
import { AsyncTypeahead } from 'react-bootstrap-typeahead';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import { search } from '../../../api/searchApi';
import Company from './SearchResults/Company';
import Game from './SearchResults/Game';
import Request from './SearchResults/Request';
import RequestGroup from './SearchResults/RequestGroup';
import SKU from './SearchResults/SKU';
import User from './SearchResults/User';
import Workflow from './SearchResults/Workflow';
import { useTranslation } from 'react-i18next';

function Search({ onFocus, onBlur, history, closeNavMenu }) {
  const typeaheadRef = useRef(null);
  const [isLoading, setIsLoading] = useState(false);
  const [options, setOptions] = useState([]);
  const handleSearch = (query) => {
    setIsLoading(true);

    // clear the current results
    setOptions([]);

    search(query)
      .then((items) => {
        setOptions(items);
        setIsLoading(false);
      })
      .catch(() => {
        setOptions([]);
        setIsLoading(false);
      });
  };
  const { t } = useTranslation();

  // Bypass client-side filtering by returning `true`. Results are already
  // filtered by the search endpoint, so no need to do it again.
  const filterBy = () => true;

  const renderResult = (option, props) => {
    switch (option.resultType) {
      case 0: //game
        return <Game option={option} />;
      case 1: //company
        return <Company option={option} />;
      case 2: //request
        return <Request option={option} />;
      case 3: //requestgroup
        return <RequestGroup option={option} />;
      case 4: //sku
        return <SKU option={option} />;
      case 5: //user
        return <User option={option} />;
      case 6: //workflow
        return <Workflow option={option} />;
      default:
        return <span>{option.title}</span>;
    }
  };
  const handleOnChange = (options) => {
    if (options && options.length > 0) {
      let option = options[0];
      if (option) {
        switch (option.resultType) {
          case 0: //game
            history.push(`/game/${option.id}`);
            break;
          case 1: //company
            break;
          case 2: //request
            break;
          case 3: //request
            history.push(`/request/${option.id}`);
            break;
          case 4: //sku
            history.push(`/game/${option.id}`);
            break;
          case 5: //user
            history.push(`/user/${option.id}`);
            break;
          case 6: //workflow
            break;
          default:
            break;
        }
      }
    }
    typeaheadRef.current.clear();
    if (closeNavMenu) {
      closeNavMenu();
    }
  };

  return (
    <>
      <AsyncTypeahead
        ref={typeaheadRef}
        delay={350}
        filterBy={filterBy}
        id="search-typeahead"
        isLoading={isLoading}
        labelKey="title"
        minLength={3}
        onSearch={handleSearch}
        options={options}
        placeholder={t('Search')}
        renderMenuItemChildren={renderResult}
        onFocus={onFocus}
        onBlur={onBlur}
        onChange={handleOnChange}
      />
    </>
  );
}
export default Search;
