import {
  apiUrl,
  getList,
  getSingleRecord,
  saveRecord,
  deleteRecord,
  putAction,
  get,
  download,
  upload,
  uploadWithMethod,
  getAuditData,
  postAction,
  handleResponse,
  deleteAction,
  exportData,
} from './apiUtils';
const baseUrl = apiUrl + '/requests/';
const exportBaseUrl = `${apiUrl}/export/requests/`;

export function getRequestsGrouped(
  assignedToMe,
  filters,
  _scopes,
  _sort,
  _order,
  _page,
  _limit
) {
  if (assignedToMe) {
    return getList(
      `${baseUrl}assignedToMe/grouped`,
      filters,
      _scopes,
      _sort,
      _order,
      _page,
      _limit
    );
  } else {
    return getList(
      `${baseUrl}grouped`,
      filters,
      _scopes,
      _sort,
      _order,
      _page,
      _limit
    );
  }
}

export function getRequests(
  assignedToMe,
  filters,
  _scopes,
  _sort,
  _order,
  _page,
  _limit
) {
  if (assignedToMe) {
    return getList(
      `${baseUrl}assignedToMe`,
      filters,
      _scopes,
      _sort,
      _order,
      _page,
      _limit
    );
  } else {
    return getList(
      `${baseUrl}search`,
      filters,
      _scopes,
      _sort,
      _order,
      _page,
      _limit
    );
  }
}

export function getRequestsMadeByMe(
  filters,
  _scopes,
  _sort,
  _order,
  _page,
  _limit
) {
  return getList(
    `${baseUrl}mine`,
    filters,
    _scopes,
    _sort,
    _order,
    _page,
    _limit
  );
}

export function getRequestsMadeByMeGrouped(
  filters,
  _scopes,
  _sort,
  _order,
  _page,
  _limit
) {
  return getList(
    `${baseUrl}mine/grouped`,
    filters,
    _scopes,
    _sort,
    _order,
    _page,
    _limit
  );
}

export function getRequestById(id) {
  return getSingleRecord(`${baseUrl}group/`, id);
}

export function getRequestByKeyRequestGroupId(id) {
  return get(`${baseUrl}group/${id}/requests`);
}

export function saveRequest(request) {
  return saveRecord(baseUrl, request);
}

export function deleteRequestGroup(id) {
  return deleteRecord(`${baseUrl}group/`, id);
}

export function submitRequestGroup(id, record) {
  return uploadWithMethod(`${baseUrl}group/${id}/submit`, record, 'PUT');
}

export function deleteRequest(requestGroupId, id) {
  return deleteRecord(`${baseUrl}group/${requestGroupId}/request/`, id);
}

export function getRequestsToBeApproved(
  filters,
  _scopes,
  _sort,
  _order,
  _page,
  _limit
) {
  return getList(
    `${baseUrl}approvals`,
    filters,
    _scopes,
    _sort,
    _order,
    _page,
    _limit
  );
}

export function getRequestsToBeApprovedGrouped(
  filters,
  _scopes,
  _sort,
  _order,
  _page,
  _limit
) {
  return getList(
    `${baseUrl}approvals/grouped`,
    filters,
    _scopes,
    _sort,
    _order,
    _page,
    _limit
  );
}

export function reassignRequest(requestId, assignToUserId) {
  if (assignToUserId) {
    // assign
    return putAction(`${baseUrl}${requestId}/reassign/${assignToUserId}`);
  }
  // unassign
  return putAction(`${baseUrl}${requestId}/reassign/`);
}

export function changeStatus(requestId, statusId, reason) {
  // single
  return putAction(`${baseUrl}bulkchangestatus`, {
    requestIds: [requestId],
    statusId,
    reason,
  });
}

export function changeStatuses(requestIds, statusId, reason) {
  // multiple
  return putAction(`${baseUrl}bulkchangestatus`, {
    requestIds,
    statusId,
    reason,
  });
}
export function getAvailableStatuses() {
  return get(`${baseUrl}availablestatuses`);
}
export function getAvailableStatusesForRequest(requestId) {
  return get(`${baseUrl}${requestId}/availablestatuses`);
}

export function withdrawRequest(requestId) {
  return putAction(`${baseUrl}${requestId}/withdraw`, null);
}

export function updateRequestValue(requestId, value, reason) {
  return putAction(`${baseUrl}${requestId}/value`, {
    value,
    reason,
  });
}

export function updateNumberOfKeys(requestId, numberOfKeys, reason) {
  return putAction(`${baseUrl}${requestId}/numberofkeys`, {
    numberOfKeys,
    reason,
  });
}

export function downloadKeys(requestId) {
  return download(`${baseUrl}${requestId}/downloadkeys`);
}

export function getApprovalStatus(requestGroupId, requestId) {
  return get(`${baseUrl}group/${requestGroupId}/${requestId}/status`);
}

export function getApprovals(requestGroupId, requestId) {
  return get(`${baseUrl}group/${requestGroupId}/${requestId}/approvals`);
}

export function uploadKeys(requestId, record) {
  return upload(`${baseUrl}${requestId}/uploadKeys`, record);
}

export function getBasket() {
  return get(`${baseUrl}basket`);
}

export function checkRequestTransactionLimits(requestId, overrideRequestValue) {
  return get(
    `${baseUrl}${requestId}/checktransactionlimits${
      overrideRequestValue
        ? '?overrideRequestValue=' + encodeURIComponent(overrideRequestValue)
        : ''
    }`
  );
}

export function checkRequestGroupTransactionLimits(requestGroupId) {
  return get(`${baseUrl}group/${requestGroupId}/checktransactionlimits`);
}

export function approveRequest(requestId, signature, overrideRequestValue) {
  return putAction(`${baseUrl}${requestId}/sign`, {
    action: 'approve',
    signature,
    overrideRequestValue,
  });
}

export function declineRequest(requestId, declineReason) {
  return putAction(`${baseUrl}${requestId}/sign`, {
    action: 'decline',
    declineReason,
  });
}

export function getAudit(id) {
  return getAuditData(`${baseUrl}group/`, id);
}

export function addAudit(id, comment) {
  return postAction(`${baseUrl}group/${id}/audit`, { comment });
}

export function downloadAccountDetailsTemplate() {
  return download(`${baseUrl}download/accountDetailsTemplate`);
}
export function uploadAccountDetails(id, record) {
  return uploadWithMethod(
    `${baseUrl}${id}/upload/accountDetails`,
    record,
    'POST'
  );
}
export function getAccountDetails(id) {
  return get(`${baseUrl}${id}/accountDetails`);
}

export function saveAccountDetails(id, record) {
  return postAction(`${baseUrl}${id}/accountDetails`, record);
}
export function deleteAccountDetail(requestId, id) {
  return deleteAction(`${baseUrl}${requestId}/accountDetails/${id}`);
}
export function sendAccountDetailEmail(requestId, id) {
  return postAction(`${baseUrl}${requestId}/accountDetails/${id}/sendemail`);
}

export function downloadAccountDetailKeyCode(requestId, accountId) {
  return fetch(
    `${baseUrl}${requestId}/accountDetails/${accountId}/download`
  ).then(handleResponse);
}
export function downloadAccountDetails(requestId) {
  return download(`${baseUrl}${requestId}/accountDetails/download`);
}

export function copyRequestGroup(id, copyDetails) {
  return postAction(`${baseUrl}group/${id}/copy`, {
    copyRequests: copyDetails,
  });
}

export function bulkSaveRequests(requests) {
  return postAction(`${baseUrl}bulk`, requests);
}

export function exportRequestData(filters, scopes) {
  return exportData(`${exportBaseUrl}search`, 'reference', filters, scopes);
}

export function exportMyRequestData(filters, scopes) {
  return exportData(`${exportBaseUrl}mine`, 'reference', filters, scopes);
}

export function exportAssignedRequestData(filters, scopes) {
  return exportData(
    `${exportBaseUrl}assignedToMe`,
    'reference',
    filters,
    scopes
  );
}

export function exportApprovalRequestData(filters, scopes) {
  return exportData(`${exportBaseUrl}approvals`, 'reference', filters, scopes);
}

export function validateCodeFile(keyRequestGroupId, filename) {
  return postAction(`${baseUrl}group/${keyRequestGroupId}/upload/validate`, {
    filename,
  });
}

export function downloadPurchaseOrder(keyRequestGroupId) {
  return download(`${baseUrl}group/${keyRequestGroupId}/download`);
}

export function getStatsByDay() {
  return get(`${baseUrl}statsByDay/`);
}

export function getAggregateStats(from, to) {
  // Format the from as YYYY-MM-DD
  from = from.toISOString().split('T')[0];
  // Format the to as YYYY-MM-DD
  to = to.toISOString().split('T')[0];

  return get(`${baseUrl}aggregateStats?fromDate=${from}&toDate=${to}`);
}

export function getCodeStatsByRequestForRequest(id) {
  return get(`${baseUrl}${id}/codeStats`);
}

export function returnKeys(requestId) {
  return postAction(`${baseUrl}${requestId}/returnKeys`);
}
