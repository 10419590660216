import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
  loadRequestsGrouped,
  exportRequestData,
  getRequestById,
  getRequestByKeyRequestGroupId,
} from '../../../../actions/requestActions';
import { Link } from 'react-router-dom';
import {
  Table,
  SimpleTextFilter,
  getTableSettingsDefaults,
} from '../../../common/Table';
import SlideoutRequestDetail from '../../slideout/RequestDetails';
import SlideoutCopyRequest from '../../slideout/CopyRequest';

import {
  CommaNumber_NoDecimal,
  CommaNumber_WithDecimal,
  WorkflowWithJustification,
  ParseBoolean,
  Date_AsString_OneLine,
  DateOnly_AsString_Raw,
} from '../../../../util/formatter';
import Loading from '../../../common/Loading';
import TimeLabel from '../../../common/TimeLabel';
import KwPanel from '../../../common/KwPanel';
import { RequestStatusLabel } from '../RequestStatusLabel';
import RequestDetailsSignatures from '../../slideout/RequestDetailsSignatures';
import useTableSettings from '../../../../hooks/useTableSettings';
import Checkbox from '../../../common/Checkbox';
import GameLink from '../../../common/links/GameLink';
import SlidingPane from 'react-sliding-pane';
import RequestGroupListButtons from '../RequestGroupListButtons';
import UserLink from '../../../common/links/UserLink';
import CompanyLink from '../../../common/links/CompanyLink';

import DateRangePicker from 'react-bootstrap-daterangepicker';
import requestStatusNames from '../../../../constants/requestStatusNames';
import PlatformLink from '../../../common/links/PlatformLink';
import { useTranslation } from 'react-i18next';

function RequestGroupedList({
  location,
  history,
  reload,
  assignedToMe,
  itemsChecked,
  setItemsChecked,
  currentUser,
  handleBulkActions,
  bulkActionTitle,
  tableViewSwitcher,
  setCompleteRequestSlideoutOpen,
  setBreadcrumb,
  scope,
  settingsKey,
  title,
}) {
  const { t } = useTranslation();
  const allColumns = [
    'reference',
    'games',
    'platform',
    'companyName',
    'requestedByCompanyName',
    'currentRequestValue',
    'numberOfKeys',
    'keyWorkflowName',
    'requestedByFullName',
    'requestedDate',
    'requiredByDate',
    'requestCompletionDate',
    'currentUserProfileGroupNames',
    'requestStatus',
  ];
  const suffixColumns = ['checkbox'];
  const defaultVisibleColumns = allColumns.filter(
    (x) => x !== 'requestedByCompanyName' && x !== 'requestCompletionDate'
  );

  const [requests, setRequests] = useState();
  const params = new URLSearchParams(location.search);
  const hideKeyRequestGroupedDetail =
    ParseBoolean(window.globalConfig?.hideKeyRequestGroupedDetail) ||
    ParseBoolean(process.env.REACT_APP_HIDE_KEY_REQUEST_GROUPED_DETAIL);
  const {
    tableSettings,
    setTableSettings,
    filterChange,
    isLoading,
    filters,
    filtersChange,
  } = useTableSettings(
    settingsKey || 'requestlist-settings',
    getTableSettingsDefaults({
      defaultSort: 'reference',
      defaultVisibleColumns,
    }),
    { assignedToMe: assignedToMe || false },
    loadRequestsGrouped,
    setRequests,
    reload,
    t('Failed to load requests'),
    location,
    scope
  );

  const [requestDateDisplayValue, setRequestDateDisplayValue] = useState();
  useEffect(() => {
    if (!requestDateDisplayValue) {
      let _from = params.get(`f_requestedDateFrom`) || null;
      let _to = params.get(`f_requestedDateTo`) || null;
      if (_from && _to) {
        let fromDate = Date.parse(_from);
        let toDate = Date.parse(_to);
        if (isNaN(fromDate) || isNaN(toDate)) {
          setRequestDateDisplayValue('');
        } else {
          setRequestDateDisplayValue(`${_from} - ${_to}`);
        }
      }
    }
  }, [params, requestDateDisplayValue]);

  const [requiredByDateDisplayValue, setRequiredByDateDisplayValue] =
    useState();
  useEffect(() => {
    if (!requiredByDateDisplayValue) {
      let _from = params.get(`f_requiredByDateFrom`) || null;
      let _to = params.get(`f_requiredByDateTo`) || null;
      if (_from && _to) {
        let fromDate = Date.parse(_from);
        let toDate = Date.parse(_to);
        if (isNaN(fromDate) || isNaN(toDate)) {
          setRequiredByDateDisplayValue('');
        } else {
          setRequiredByDateDisplayValue(`${_from} - ${_to}`);
        }
      }
    }
  }, [params, requiredByDateDisplayValue]);

  const [
    requestCompletionDateDisplayValue,
    setRequestCompletionDateDisplayValue,
  ] = useState();
  useEffect(() => {
    if (!requestCompletionDateDisplayValue) {
      let _from = params.get(`f_requestCompletionDateFrom`) || null;
      let _to = params.get(`f_requestCompletionDateTo`) || null;
      if (_from && _to) {
        let fromDate = Date.parse(_from);
        let toDate = Date.parse(_to);
        if (isNaN(fromDate) || isNaN(toDate)) {
          setRequestCompletionDateDisplayValue('');
        } else {
          setRequestCompletionDateDisplayValue(`${_from} - ${_to}`);
        }
      }
    }
  }, [params, requestCompletionDateDisplayValue]);

  // slideout panel
  const [detailsSlideoutOpen, setDetailsSlideoutOpen] = useState(false);
  const [detailsSlideoutItem, setDetailsSlideoutItem] = useState();
  const [signaturesSlideoutOpen, setSignaturesSlideoutOpen] = useState(false);
  const [signaturesSlideoutItem, setSignaturesSlideoutItem] = useState();
  const [copyRequestSlideoutOpen, setCopyRequestSlideoutOpen] = useState(false);
  const [copyRequestSlideoutRequests, setCopyRequestSlideoutRequests] =
    useState();
  const [copyRequestSlideoutRequestGroup, setCopyRequestSlideoutRequestGroup] =
    useState();

  const handleCopyRequest = (e, requestGroup) => {
    e.cancelBubble = true;
    setCopyRequestSlideoutOpen(true);
    getRequestById(requestGroup.id).then((d) => {
      setCopyRequestSlideoutRequestGroup(d);
      // individual requests
      getRequestByKeyRequestGroupId(requestGroup.id)
        .then((d) => {
          setCopyRequestSlideoutRequests(d);
        })
        .catch((e) => {
          console.error(e);
        });
    });
  };

  const handleShowDetails = (e, request) => {
    e.cancelBubble = true;

    if (request.games.length > 1) {
      return;
    }

    setDetailsSlideoutItem(request);
    setDetailsSlideoutOpen(true);
  };

  let handleShowSignatures = (e, requestStep) => {
    setSignaturesSlideoutItem(requestStep);
    setSignaturesSlideoutOpen(true);
  };

  // The base config of the columns, this will be filtered based on the visible (above)
  let columnsConfig = [
    {
      Key: 'reference',
      Label: '#',
      Render: (row) => <Link to={'/request/' + row.id}>{row.reference}</Link>,
      Filter: {
        Render: (col) => (
          <SimpleTextFilter
            Key={col.Key}
            Value={params.get(`f_${[col.Key]}`) || ''}
            onChange={(value) => filterChange(col.Key, value)}
          />
        ),
      },
    },
    {
      Key: 'games',
      Label: t('Games'),
      Render: (row) =>
        row.games && row.games.length > 0
          ? hideKeyRequestGroupedDetail && row.games.length > 1
            ? t('Multiple')
            : row.games.map((x, i) => (
                <GameLink
                  gameId={x.id}
                  skuId={x.skuId}
                  gameTitle={x.gameTitle}
                  skuName={x.skuName}
                  key={`${row.id},${x.skuId}`}
                  currentUser={currentUser}
                  className={i < row.games.length - 1 ? 'space-bottom' : ''}
                />
              ))
          : '',
      Filter: {
        Render: (col) => (
          <SimpleTextFilter
            Key={col.Key}
            Value={params.get(`f_${[col.Key]}`) || ''}
            onChange={(value) => filterChange(col.Key, value)}
          />
        ),
      },
    },
    {
      Key: 'platform',
      Label: t('Platform'),
      Render: (row) =>
        row.games && row.games.length > 0
          ? hideKeyRequestGroupedDetail && row.games.length > 1
            ? t('Multiple')
            : row.games.map((x, i) => (
                <PlatformLink
                  id={x.platformId}
                  name={x.platform}
                  key={`${row.id},${x.platformId}`}
                  className={i < row.games.length - 1 ? 'mb-4' : ''}
                  currentUser={currentUser}
                />
              ))
          : '',
      Filter: {
        Render: (col) => (
          <SimpleTextFilter
            Key={col.Key}
            Value={params.get(`f_${[col.Key]}`) || ''}
            onChange={(value) => filterChange(col.Key, value)}
          />
        ),
      },
    },
    {
      Key: 'companyName',
      Label: t('Company'),
      Render: (row) => (
        <CompanyLink id={row.companyId} name={row.companyName} />
      ),
      Filter: {
        Render: (col) => (
          <SimpleTextFilter
            Key={col.Key}
            Value={params.get(`f_${[col.Key]}`) || ''}
            onChange={(value) => filterChange(col.Key, value)}
          />
        ),
      },
    },
    {
      Key: 'requestedByCompanyName',
      Label: t('Requested by Company'),
      Render: (row) => (
        <CompanyLink
          id={row.requestedByCompanyId}
          name={row.requestedByCompanyName}
        />
      ),
      Filter: {
        Render: (col) => (
          <SimpleTextFilter
            Key={col.Key}
            Value={params.get(`f_${[col.Key]}`) || ''}
            onChange={(value) => filterChange(col.Key, value)}
          />
        ),
      },
    },
    {
      Key: 'currentRequestValue',
      Label: t('Value'),
      className: 'text-right num no-wrap',
      Render: (row) => CommaNumber_WithDecimal(row.currentRequestValue),
    },
    {
      Key: 'numberOfKeys',
      Label: t('Keys'),
      className: 'text-right num no-wrap',
      Render: (row) => CommaNumber_NoDecimal(row.numberOfKeys),
    },
    {
      Key: 'keyWorkflowName',
      Label: t('Type'),
      Render: (row) => WorkflowWithJustification(row),
      Filter: {
        Render: (col) => (
          <SimpleTextFilter
            Key={col.Key}
            Value={params.get(`f_${[col.Key]}`) || ''}
            onChange={(value) => filterChange(col.Key, value)}
          />
        ),
      },
    },
    {
      Key: 'requestedByFullName',
      Label: t('Requested by'),
      Render: (row) => (
        <>
          <UserLink id={row.requestedById} name={row.requestedByFullName} />
          <br />
          {row.requestedByCompanyName}
        </>
      ),
      Filter: {
        Render: (col) => (
          <SimpleTextFilter
            Key={col.Key}
            Value={params.get(`f_${[col.Key]}`) || ''}
            onChange={(value) => filterChange(col.Key, value)}
          />
        ),
      },
    },
    {
      Key: 'requestedDate',
      Label: t('Request date'),
      Render: (row) => (
        <>
          <div>{Date_AsString_OneLine(row.requestedDate)}</div>

          <TimeLabel
            elapsedMinutesFormatted={row.elapsedMinutesFormatted}
            breached={row.isSLABreached}
            title={t('Time between request and completion')}
            ongoing={row.requestCompletionDate ? false : true}
          />
        </>
      ),
      Filter: {
        Render: (col) => (
          <DateRangePicker
            initialSettings={{
              showDropdowns: true,
              minYear: 2010,
              buttonClasses: 'btn btn-default',
              autoUpdateInput: false,
              locale: {
                format: 'DD-MMM-YYYY',
                applyLabel: t('Apply'),
                cancelLabel: t('Clear'),
              },
            }}
            onApply={(e, picker) => {
              let from = picker.startDate.format('DD-MMM-YYYY');
              let to = picker.endDate.format('DD-MMM-YYYY');
              filtersChange([
                { key: 'requestedDateFrom', value: from },
                { key: 'requestedDateTo', value: to },
              ]);
              setRequestDateDisplayValue(`${from} - ${to}`);
            }}
            onCancel={(e, picker) => {
              picker.setStartDate(new Date());
              picker.setEndDate(new Date());
              filtersChange([
                { key: 'requestedDateFrom', value: '' },
                { key: 'requestedDateTo', value: '' },
              ]);
              setRequestDateDisplayValue('');
            }}
          >
            <input
              type="text"
              className={`form-control ${
                requestDateDisplayValue ? 'filtered' : ''
              }`}
              value={requestDateDisplayValue}
              onChange={(e) => {
                setRequestDateDisplayValue(e.target.value);
              }}
            />
          </DateRangePicker>
        ),
      },
    },
    {
      Key: 'requiredByDate',
      Label: t('Required by date'),
      Render: (row) => <>{DateOnly_AsString_Raw(row.requiredByDate)}</>,
      Filter: {
        Render: (col) => (
          <DateRangePicker
            initialSettings={{
              showDropdowns: true,
              minYear: 2010,
              buttonClasses: 'btn btn-default',
              autoUpdateInput: false,
              locale: {
                format: 'DD-MMM-YYYY',
                applyLabel: 'Apply',
                cancelLabel: 'Clear',
              },
            }}
            onApply={(e, picker) => {
              let from = picker.startDate.format('DD-MMM-YYYY');
              let to = picker.endDate.format('DD-MMM-YYYY');
              filtersChange([
                { key: 'requiredByDateFrom', value: from },
                { key: 'requiredByDateTo', value: to },
              ]);
              setRequiredByDateDisplayValue(`${from} - ${to}`);
            }}
            onCancel={(e, picker) => {
              picker.setStartDate(new Date());
              picker.setEndDate(new Date());
              filtersChange([
                { key: 'requiredByDateFrom', value: '' },
                { key: 'requiredByDateTo', value: '' },
              ]);
              setRequiredByDateDisplayValue('');
            }}
          >
            <input
              type="text"
              className={`form-control ${
                requiredByDateDisplayValue ? 'filtered' : ''
              }`}
              value={requiredByDateDisplayValue}
              onChange={(e) => {
                setRequiredByDateDisplayValue(e.target.value);
              }}
            />
          </DateRangePicker>
        ),
      },
    },
    {
      Key: 'requestCompletionDate',
      Label: t('Completed'),
      Render: (row) =>
        row.requestCompletionDate && (
          <>
            <div>{Date_AsString_OneLine(row.requestCompletionDate)}</div>
            <TimeLabel
              elapsedMinutesFormatted={row.elapsedMinutesFormatted}
              breached={row.isSLABreached}
              title={t('Time between request and completion')}
            />
          </>
        ),
      Filter: {
        Render: (col) => (
          <DateRangePicker
            initialSettings={{
              showDropdowns: true,
              minYear: 2010,
              buttonClasses: 'btn btn-default',
              autoUpdateInput: false,
              locale: {
                format: 'DD-MMM-YYYY',
                applyLabel: t('Apply'),
                cancelLabel: t('Clear'),
              },
            }}
            onApply={(e, picker) => {
              let from = picker.startDate.format('DD-MMM-YYYY');
              let to = picker.endDate.format('DD-MMM-YYYY');
              filtersChange([
                { key: 'requestCompletionDateFrom', value: from },
                { key: 'requestCompletionDateTo', value: to },
              ]);
              setRequestCompletionDateDisplayValue(`${from} - ${to}`);
            }}
            onCancel={(e, picker) => {
              picker.setStartDate(new Date());
              picker.setEndDate(new Date());
              filtersChange([
                { key: 'requestCompletionDateFrom', value: '' },
                { key: 'requestCompletionDateTo', value: '' },
              ]);
              setRequestCompletionDateDisplayValue('');
            }}
          >
            <input
              type="text"
              className={`form-control ${
                requestCompletionDateDisplayValue ? 'filtered' : ''
              }`}
              value={requestCompletionDateDisplayValue}
              onChange={(e) => {
                setRequestCompletionDateDisplayValue(e.target.value);
              }}
            />
          </DateRangePicker>
        ),
      },
    },
    {
      Key: 'currentUserProfileGroupNames',
      Label: t('Waiting for'),
      Render: (row) =>
        row.currentUserProfileGroupNames &&
        row.currentUserProfileGroupNames.length > 0
          ? row.currentUserProfileGroupNames.map((x) =>
              row.games.length > 1 ? (
                <span title={t('Multiple games are on this request')} key={x}>
                  {x}
                </span>
              ) : (
                <div
                  className="a"
                  key={x}
                  onClick={(e) => handleShowDetails(e, row)}
                  tabIndex={0} // Add tabIndex attribute to make it focusable
                >
                  {x}
                </div>
              )
            )
          : '',
      Filter: {
        Render: (col) => (
          <SimpleTextFilter
            Key={col.Key}
            Value={params.get(`f_${[col.Key]}`) || ''}
            onChange={(value) => filterChange(col.Key, value)}
          />
        ),
      },
    },
    {
      Key: 'requestStatus',
      Label: t('Status'),
      Render: (row) => {
        if (row.games && row.games.length > 0) {
          let uniqueByStatus = row.games.filter(
            (value, index, self) =>
              self.findIndex(
                (z) =>
                  z.requestStatus.id === value.requestStatus.id &&
                  z.isWaitingForKeys === value.isWaitingForKeys
              ) === index
          );
          return uniqueByStatus.map((x, i) => (
            <div className="mb-2" key={i}>
              <RequestStatusLabel
                requestStatus={x.requestStatus}
                isWaitingForKeys={x.isWaitingForKeys}
                isDownloaded={x.downloaded}
              />
            </div>
          ));
        }
        return null;
      },
      Filter: {
        Render: (col) => (
          <select
            className={`form-control ${
              params.get(`f_${[col.Key]}`) ? 'filtered' : ''
            }`}
            value={params.get(`f_${[col.Key]}`) || ''}
            onChange={(el) => filterChange(col.Key, el.target.value)}
          >
            <option value="">{t('All')}</option>
            {requestStatusNames.map((x) => (
              <option key={x} value={x}>
                {t(x)}
              </option>
            ))}
          </select>
        ),
      },
    },
    {
      Key: 'checkbox',
      Label: '',
      className: 'text-right no-navigate',
      Sort: false,
      width: '2%',
      Render: (item) => (
        <div className="d-flex align-items-center justify-content-end">
          <RequestGroupListButtons
            row={item}
            handleCopyRequest={handleCopyRequest}
            isProcessing={false}
            currentUser={currentUser}
          />
          <Checkbox
            className="ms-3"
            checked={itemsChecked?.some((x) => x.id === item.id)}
            onChange={(checked) =>
              checked
                ? setItemsChecked([...itemsChecked, item])
                : setItemsChecked(itemsChecked.filter((x) => x.id !== item.id))
            }
          ></Checkbox>
        </div>
      ),
      Filter: {
        Render: () => (
          <Checkbox
            disabled={!requests}
            checked={false}
            onChange={(checked) =>
              checked ? setItemsChecked(requests?.data) : setItemsChecked([])
            }
          ></Checkbox>
        ),
      },
    },
  ];

  // filter
  const visibleColumns = tableSettings.columns ?? defaultVisibleColumns;
  const suffixColumnsWithConfig = suffixColumns
    .map((x) => columnsConfig.find((y) => y.Key === x))
    .filter((x) => x);
  const columns = visibleColumns
    .map((x) => columnsConfig.find((y) => y.Key === x))
    .filter((x) => x);

  // return an alaska table
  return requests?.data ? (
    <>
      <KwPanel className="mb-4">
        {title && (
          <header className="mb-2">
            <h2 className="no-margin">{title}</h2>
          </header>
        )}
        <Table
          columns={columns}
          suffixColumns={suffixColumnsWithConfig}
          allColumns={allColumns}
          defaultVisibleColumns={defaultVisibleColumns}
          rows={requests.data.map((x) => {
            return { Key: x.id, ...x };
          })}
          totalRowCount={requests.totalRowCount}
          filteredRowCount={requests.filteredRowCount}
          scopes={requests.scopes}
          tableSettings={tableSettings}
          setTableSettings={setTableSettings}
          isLoading={isLoading}
          filters={filters}
          filterChange={filterChange}
          className="td-align-top"
          bulkActionTitle={bulkActionTitle}
          itemsChecked={itemsChecked}
          setBulkActionsSlideoutOpen={handleBulkActions}
          tableActionBarButtons={tableViewSwitcher}
          exportData={exportRequestData}
          location={location}
          setBreadcrumb={setBreadcrumb}
          hideScopeTags={scope}
        />
      </KwPanel>

      <SlidingPane
        isOpen={detailsSlideoutOpen}
        hideHeader={true}
        from="right"
        className="side-panel"
        onRequestClose={() => setDetailsSlideoutOpen(false)}
      >
        <>
          {detailsSlideoutItem && (
            <SlideoutRequestDetail
              requestGroupId={detailsSlideoutItem.id}
              requestId={detailsSlideoutItem.games[0].requestId}
              history={history}
              done={(d) => {
                setDetailsSlideoutOpen(false);
              }}
              cancel={() => setDetailsSlideoutOpen(false)}
              onShowSignatures={handleShowSignatures}
            />
          )}
        </>
      </SlidingPane>

      <SlidingPane
        isOpen={signaturesSlideoutOpen}
        hideHeader={true}
        from="right"
        className="small-side-panel"
        onRequestClose={() => setSignaturesSlideoutOpen(false)}
      >
        <>
          {signaturesSlideoutItem && (
            <RequestDetailsSignatures
              done={(d) => {
                setSignaturesSlideoutOpen(false);
              }}
              cancel={() => setSignaturesSlideoutOpen(false)}
              requestStep={signaturesSlideoutItem}
            />
          )}
        </>
      </SlidingPane>

      <SlidingPane
        isOpen={copyRequestSlideoutOpen}
        hideHeader={true}
        from="right"
        className="xlarge-side-panel"
        onRequestClose={() => setCopyRequestSlideoutOpen(false)}
      >
        <>
          {copyRequestSlideoutRequestGroup && copyRequestSlideoutRequests ? (
            <SlideoutCopyRequest
              done={(d) => {
                setCopyRequestSlideoutOpen(false);
              }}
              cancel={() => setCopyRequestSlideoutOpen(false)}
              requestGroup={copyRequestSlideoutRequestGroup}
              requests={copyRequestSlideoutRequests}
              history={history}
              currentUser={currentUser}
              setCompleteRequestSlideoutOpen={setCompleteRequestSlideoutOpen}
            />
          ) : (
            <Loading></Loading>
          )}
        </>
      </SlidingPane>
    </>
  ) : (
    <Loading></Loading>
  );
}

function mapStateToProps(state, ownProps) {
  return {
    currentUser: state.auth,
  };
}

const mapDispatchToProps = {};

RequestGroupedList.propTypes = {
  location: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  reload: PropTypes.bool,
  assignedToMe: PropTypes.bool,
  itemsChecked: PropTypes.array.isRequired,
  setItemsChecked: PropTypes.func.isRequired,
  currentUser: PropTypes.object.isRequired,
  handleBulkActions: PropTypes.func.isRequired,
  bulkActionTitle: PropTypes.string.isRequired,
  tableViewSwitcher: PropTypes.object.isRequired,
  setCompleteRequestSlideoutOpen: PropTypes.func.isRequired,
  setBreadcrumb: PropTypes.func,
  scope: PropTypes.object,
  settingsKey: PropTypes.string,
  title: PropTypes.string,
};

export default connect(mapStateToProps, mapDispatchToProps)(RequestGroupedList);
