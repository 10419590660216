import React, { useMemo, useCallback } from 'react';
import { useDropzone } from 'react-dropzone';
import PropTypes from 'prop-types';
import { ByteLength_AsString } from '../../util/formatter';
import FormGroup from './FormGroup';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation, Trans } from 'react-i18next';
import icons from '../../constants/icons';

const FormFileUpload = ({
  label,
  error,
  errorMessage,
  required,
  addFiles,
  removeFile,
  fileExtension,
  hideFiles,
  files = [],
}) => {
  const { t } = useTranslation();
  // UseCallback to handle file drop
  const onDrop = useCallback(
    (droppedFiles) => {
      addFiles(droppedFiles);
    },
    [addFiles]
  );

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject,
  } = useDropzone({ onDrop });

  // Map through the provided filesList for display
  const filesListed = files.map((f) => (
    <li key={f.path || f.name}>
      <span>
        <FontAwesomeIcon icon={icons.FILE} className="me-3" />
        <b>{f.path || f.name}</b>
      </span>
      <div>
        <span>{ByteLength_AsString(f.size)}</span>
        <button
          className="ms-3 a"
          title={t('Remove')}
          onClick={(e) => {
            removeFile(f);
          }}
        >
          <FontAwesomeIcon icon={icons.DELETE_TAG}></FontAwesomeIcon>
        </button>
      </div>
    </li>
  ));

  const classeNames = useMemo(
    () =>
      `dropzone ${isDragActive ? 'active' : ''} ${
        isDragAccept ? 'accept positive-colour1' : ''
      } ${isDragReject ? 'reject' : ''}`,
    [isDragActive, isDragReject, isDragAccept]
  );

  return (
    <FormGroup
      label={label}
      error={error}
      errorMessage={errorMessage}
      required={required}
    >
      <>
        <div {...getRootProps({ className: classeNames })}>
          <input {...getInputProps()} />
          <p>
            <Trans
              i18nKey="dragAndDropInstruction"
              components={{ bold: <b /> }}
              values={{ fileExtension: fileExtension || '.txt' }}
            >
              Drag &amp; drop <bold>{{ fileExtension }}</bold> files here, or
              click to select files
            </Trans>
          </p>
        </div>
        {files.length > 0 && !hideFiles ? (
          <aside className="drop-files">
            <h4>{t('Files')}</h4>
            <ul>{filesListed}</ul>
          </aside>
        ) : null}
      </>
    </FormGroup>
  );
};

FormFileUpload.propTypes = {
  label: PropTypes.string.isRequired,
  error: PropTypes.string,
  errorMessage: PropTypes.string,
  required: PropTypes.bool,
  addFiles: PropTypes.func.isRequired,
  removeFile: PropTypes.func.isRequired,
  fileExtension: PropTypes.string,
  hideFiles: PropTypes.bool,
  files: PropTypes.array.isRequired,
};

export default FormFileUpload;
