import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import SlideoutLayout from '../../layout/slideout/SlideoutLayout';
import UploadActivatedForm from './UploadActivatedForm';
import { getKeyProviders } from '../../../actions/keyProviderActions';
import { uploadActivations } from '../../../actions/keyCodeActions';
import { useForm } from 'react-hook-form';
import PropTypes from 'prop-types';
import Loading from '../../common/Loading';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';

function UploadActivated({ done, cancel, keyProviders, getKeyProviders }) {
  const { t } = useTranslation();
  const {
    register,
    formState: { errors },
    handleSubmit,
    control,
  } = useForm();
  const [activatedFiles, setActivatedFiles] = useState([]); // State to track multiple files

  const addFiles = (files) => {
    setActivatedFiles((prevFiles) => [...prevFiles, ...files]); // Add new files to the existing list
  };
  const removeFile = (file) => {
    setActivatedFiles((prevFiles) =>
      prevFiles.filter((prevFile) => prevFile !== file)
    ); // Remove file from the list
  };
  const onSubmit = (data) => {
    Promise.all(
      activatedFiles.map((file) => uploadActivations({ file, ...data }))
    )
      .then(() => {
        toast.success(t('Activations successfully uploaded'));
        done();
      })
      .catch((e) => {
        toast.error(e.message || t('Failed to upload activations'));
      });
  };
  useEffect(() => {
    if (!keyProviders) {
      getKeyProviders();
    }
  }, [keyProviders, getKeyProviders]);

  return keyProviders ? (
    <SlideoutLayout
      title={t('Upload activated keys')}
      cancel={cancel}
      done={done}
    >
      <UploadActivatedForm
        keyProviders={keyProviders}
        register={register}
        errors={errors}
        control={control}
        cancel={cancel}
        submit={handleSubmit(onSubmit)}
        addFiles={addFiles}
        removeFile={removeFile}
        files={activatedFiles}
      ></UploadActivatedForm>
    </SlideoutLayout>
  ) : (
    <Loading></Loading>
  );
}

function mapStateToProps(state, ownProps) {
  return {
    keyProviders: state.keyProviders,
  };
}

const mapDispatchToProps = {
  getKeyProviders,
};

UploadActivated.propTypes = {
  done: PropTypes.func.isRequired,
  cancel: PropTypes.func.isRequired,
  getKeyProviders: PropTypes.func,
  keyProviders: PropTypes.array,
};

export default connect(mapStateToProps, mapDispatchToProps)(UploadActivated);
