import React from 'react';
import PropTypes from 'prop-types';
import LeftNavWrapper from '../menu-components/LeftNavWrapper';
import LeftMenuItem from '../menu-components/LeftMenuItem';
import permissionTypes from '../../../constants/permissionTypes';
import NavColours from '../../../constants/NavColours';
import { useTranslation } from 'react-i18next';
import icons from '../../../constants/icons';

function LeftMenu_Reports({ currentUser, wrapperClassName }) {
  const { t } = useTranslation();

  return (
    <LeftNavWrapper className={wrapperClassName}>
      <LeftMenuItem
        url="/reports/requests-by-game"
        activeRegex="\/reports/requests-by-game(\?.+)?$"
        icon={icons.REPORT}
        colour={NavColours.NAV_COLOUR_1}
        title={t('Requests / Title')}
        currentUser={currentUser}
        allowedPermissions={[permissionTypes.VIEW_REPORTS]}
      />
      <LeftMenuItem
        url="/reports/requests-by-sku"
        activeRegex="\/reports/requests-by-sku(\?.+)?$"
        icon={icons.REPORT}
        colour={NavColours.NAV_COLOUR_1}
        title={t('Requests / SKU')}
        currentUser={currentUser}
        allowedPermissions={[permissionTypes.VIEW_REPORTS]}
      />
      <LeftMenuItem
        url="/reports/requests-by-company"
        icon={icons.REPORT}
        colour={NavColours.NAV_COLOUR_1}
        title={t('Requests / Company')}
        currentUser={currentUser}
        allowedPermissions={[permissionTypes.VIEW_REPORTS]}
      />
      <LeftMenuItem
        url="/reports/requests-by-workflow"
        icon={icons.REPORT}
        colour={NavColours.NAV_COLOUR_1}
        title={t('Requests / Workflow')}
        currentUser={currentUser}
        allowedPermissions={[permissionTypes.VIEW_REPORTS]}
      />
      <hr />
      <LeftMenuItem
        url="/reports/keys-by-game"
        icon={icons.REPORT}
        colour={NavColours.NAV_COLOUR_2}
        title={t('Keys / Title')}
        currentUser={currentUser}
        allowedPermissions={[permissionTypes.VIEW_REPORTS]}
      />
      <LeftMenuItem
        url="/reports/keys-by-sku"
        icon={icons.REPORT}
        colour={NavColours.NAV_COLOUR_2}
        title={t('Keys / SKU')}
        currentUser={currentUser}
        allowedPermissions={[permissionTypes.VIEW_REPORTS]}
      />
      <LeftMenuItem
        url="/reports/keys-by-company"
        activeRegex="\/(reports/keys-by-company)?\/(?!\?)"
        icon={icons.REPORT}
        colour={NavColours.NAV_COLOUR_2}
        title={t('Keys / Company')}
        currentUser={currentUser}
        allowedPermissions={[permissionTypes.VIEW_REPORTS]}
      />
      <LeftMenuItem
        url="/reports/keys-by-company-by-status"
        icon={icons.REPORT}
        colour={NavColours.NAV_COLOUR_2}
        title={t('Keys / Company / Status')}
        currentUser={currentUser}
        allowedPermissions={[permissionTypes.VIEW_REPORTS]}
      />
      <LeftMenuItem
        url="/reports/keys-by-workflow"
        icon={icons.REPORT}
        colour={NavColours.NAV_COLOUR_2}
        title={t('Keys / Workflow')}
        currentUser={currentUser}
        allowedPermissions={[permissionTypes.VIEW_REPORTS]}
      />
      <hr />
      <LeftMenuItem
        url="/reports/sla-company"
        icon={icons.REPORT}
        colour={NavColours.NAV_COLOUR_3}
        title={t('Target by company')}
        currentUser={currentUser}
        allowedPermissions={[permissionTypes.VIEW_REPORTS]}
      />
      <LeftMenuItem
        url="/reports/sla-sku"
        icon={icons.REPORT}
        colour={NavColours.NAV_COLOUR_3}
        title={t('Target by title')}
        currentUser={currentUser}
        allowedPermissions={[permissionTypes.VIEW_REPORTS]}
      />
      <LeftMenuItem
        url="/reports/sla-workflow"
        icon={icons.REPORT}
        colour={NavColours.NAV_COLOUR_3}
        title={t('Target by workflow')}
        currentUser={currentUser}
        allowedPermissions={[permissionTypes.VIEW_REPORTS]}
      />
      <hr />
      <LeftMenuItem
        url="/reports/requests-authorising-user"
        icon={icons.REPORT}
        colour={NavColours.NAV_COLOUR_4}
        title={t('Authorised requests / user')}
        currentUser={currentUser}
        allowedPermissions={[permissionTypes.VIEW_REPORTS]}
      />
      <LeftMenuItem
        url="/reports/keys-authorising-user"
        icon={icons.REPORT}
        colour={NavColours.NAV_COLOUR_4}
        title={t('Authorised keys / user')}
        currentUser={currentUser}
        allowedPermissions={[permissionTypes.VIEW_REPORTS]}
      />
      <hr />
      <LeftMenuItem
        url="/reports/requests-uploading-user"
        icon={icons.REPORT}
        colour={NavColours.NAV_COLOUR_5}
        title={t('Uploads / user')}
        currentUser={currentUser}
        allowedPermissions={[permissionTypes.VIEW_REPORTS]}
      />
      <LeftMenuItem
        url="/reports/keys-uploading-user"
        icon={icons.REPORT}
        colour={NavColours.NAV_COLOUR_5}
        title={t('Uploaded keys / user')}
        currentUser={currentUser}
        allowedPermissions={[permissionTypes.VIEW_REPORTS]}
      />
      <hr />
      <LeftMenuItem
        url="/reports/workflows-requesters"
        icon={icons.REPORT}
        colour={NavColours.NAV_COLOUR_6}
        title={t('Requesters / workflow')}
        currentUser={currentUser}
        allowedPermissions={[permissionTypes.VIEW_REPORTS]}
      />
      <LeftMenuItem
        url="/reports/workflows-approvers"
        icon={icons.REPORT}
        colour={NavColours.NAV_COLOUR_6}
        title={t('Approvers / workflow')}
        currentUser={currentUser}
        allowedPermissions={[permissionTypes.VIEW_REPORTS]}
      />
      <hr />
      <LeftMenuItem
        url="/reports/games-added"
        icon={icons.REPORT}
        colour={NavColours.NAV_COLOUR_7}
        title={t('Games modified / user')}
        currentUser={currentUser}
        allowedPermissions={[permissionTypes.VIEW_REPORTS]}
      />
      <LeftMenuItem
        url="/reports/skus-added"
        icon={icons.REPORT}
        colour={NavColours.NAV_COLOUR_7}
        title={t('SKUs modified / user')}
        currentUser={currentUser}
        allowedPermissions={[permissionTypes.VIEW_REPORTS]}
      />
      <LeftMenuItem
        url="/reports/users-added"
        icon={icons.REPORT}
        colour={NavColours.NAV_COLOUR_7}
        title={t('Users modified / user')}
        currentUser={currentUser}
        allowedPermissions={[permissionTypes.VIEW_REPORTS]}
      />
      <LeftMenuItem
        url="/reports/keyworkflows-added"
        icon={icons.REPORT}
        colour={NavColours.NAV_COLOUR_7}
        title={t('Workflows modified / user')}
        currentUser={currentUser}
        allowedPermissions={[permissionTypes.VIEW_REPORTS]}
      />
      <hr />
    </LeftNavWrapper>
  );
}

LeftMenu_Reports.propTypes = {
  currentUser: PropTypes.object,
};

export default LeftMenu_Reports;
