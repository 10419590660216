import React from 'react';
import FormGroup from './FormGroup';
const FormButtonGroup = ({
  buttons,
  name,
  label,
  error,
  errorMessage,
  required,
  extraRowClasses = 'align-items-start',
  onChange,
  value,
  register,
  toggleGroupClassName,
  helpContent,
}) => {
  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      const activeButton = buttons.find((btn) => btn.value === value);
      const activeIndex = buttons.indexOf(activeButton);
      const nextIndex =
        activeIndex === buttons.length - 1 ? 0 : activeIndex + 1;
      onChange(buttons[nextIndex]);
    }
  };

  return (
    <FormGroup
      label={label}
      error={error}
      errorMessage={errorMessage}
      required={required}
      extraRowClasses={extraRowClasses}
      helpContent={helpContent}
    >
      <div className={`toggle-group ${toggleGroupClassName}`}>
        {buttons.map((btn) => {
          return typeof btn === 'object' ? (
            <label
              key={btn.value}
              className={value === btn.value ? 'checked' : ''}
              onClick={() => onChange(btn)}
              onKeyDown={(event) => {
                if (event.key === 'Enter') {
                  console.log('enter');
                  onChange(btn);
                }
              }}
              tabIndex="0"
            >
              {btn.text}
            </label>
          ) : (
            <label
              key={btn}
              className={value === btn ? 'checked' : ''}
              onClick={() => onChange(btn)}
              onKeyDown={(event) => {
                if (event.key === 'Enter') {
                  console.log('enter');
                  onChange(btn);
                }
              }}
              tabIndex="0"
            >
              {btn}
            </label>
          );
        })}
      </div>
    </FormGroup>
  );
};
export default FormButtonGroup;
