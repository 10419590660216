import React, { useState } from 'react';
import {
  exportKeysByPartnerByStatusPivot,
  loadKeysByPartnerByStatusPivot,
} from '../../../actions/reportActions';
import {
  SimpleTextFilter,
  Table,
  getTableSettingsDefaults,
} from '../../common/Table';
import { CommaNumber_NoDecimal } from '../../../util/formatter';
import Loading from '../../common/Loading';
import KwPanel from '../../common/KwPanel';
import useTableSettings from '../../../hooks/useTableSettings';
import { useTranslation } from 'react-i18next';
import GameLink from '../../common/links/GameLink';
import CompanyLink from '../../common/links/CompanyLink';
import SKULink from '../../common/links/SKULink';
import { hasPermission } from '../../auth/authUtils';
import permissionTypes from '../../../constants/permissionTypes';
import { NavLink } from 'react-router-dom';

function KeysByCompanyByStatusList({ location, currentUser }) {
  const { t } = useTranslation();
  const allColumns = [
    'companyName',
    'game',
    'sku',
    'allocated',
    'sold',
    'activated',
    'disabled',
    'expired',
  ];
  const defaultVisibleColumns = allColumns;
  const [reload] = useState();
  const [items, setItems] = useState();
  const params = new URLSearchParams(location.search);
  const { tableSettings, setTableSettings, filters, filterChange, isLoading } =
    useTableSettings(
      'keysbycompanybystatusreport-settings',
      getTableSettingsDefaults({
        defaultSort: 'companyName',
        defaultVisibleColumns,
      }),
      null,
      loadKeysByPartnerByStatusPivot,
      setItems,
      reload,
      t('Failed to load report'),
      location
    );

  // The base config of the columns, this will be filtered based on the visible (above)
  let columnsConfig = [
    {
      Key: 'companyName',
      Label: t('Company'),
      Render: (row) => {
        return (
          <CompanyLink
            id={row.companyId}
            name={row.companyName}
            currentUser={currentUser}
          />
        );
      },
      Filter: {
        Render: (col) => (
          <SimpleTextFilter
            Key={col.Key}
            Value={params.get(`f_${[col.Key]}`) || ''}
            onChange={(value) => filterChange(col.Key, value)}
          />
        ),
      },
    },
    {
      Key: 'game',
      Label: t('Title'),
      Render: (row) => {
        return (
          <GameLink
            gameId={row.gameId}
            gameTitle={row.game}
            currentUser={currentUser}
          />
        );
      },
      Filter: {
        Render: (col) => (
          <SimpleTextFilter
            Key={col.Key}
            Value={params.get(`f_${[col.Key]}`) || ''}
            onChange={(value) => filterChange(col.Key, value)}
          />
        ),
      },
    },
    {
      Key: 'sku',
      Label: t('SKU'),
      Render: (row) => {
        return (
          <SKULink
            skuId={row.skuId}
            skuName={row.sku}
            currentUser={currentUser}
          />
        );
      },
      Filter: {
        Render: (col) => (
          <SimpleTextFilter
            Key={col.Key}
            Value={params.get(`f_${[col.Key]}`) || ''}
            onChange={(value) => filterChange(col.Key, value)}
          />
        ),
      },
    },
    {
      Key: 'allocated',
      Label: t('Allocated'),
      className: 'text-right num no-wrap',
      Render: (row) => CommaNumber_NoDecimal(row.allocated),
    },
    {
      Key: 'sold',
      Label: t('Sold'),
      className: 'text-right num no-wrap',
      Render: (row) => {
        return hasPermission(currentUser, permissionTypes.MANAGE_KEYS) ? (
          <NavLink
            to={`/keys/reported-sold?s_sku=${row.skuId}&s_companyid=${row.companyId}`}
          >
            {CommaNumber_NoDecimal(row.sold)}
          </NavLink>
        ) : (
          CommaNumber_NoDecimal(row.sold)
        );
      },
    },
    {
      Key: 'activated',
      Label: t('Activated'),
      className: 'text-right num no-wrap',
      Render: (row) => {
        return hasPermission(currentUser, permissionTypes.MANAGE_KEYS) ? (
          <NavLink
            to={`/keys/reported-activated/?s_sku=${row.skuId}&s_companyid=${row.companyId}`}
          >
            {CommaNumber_NoDecimal(row.activated)}
          </NavLink>
        ) : (
          CommaNumber_NoDecimal(row.activated)
        );
      },
    },
    {
      Key: 'disabled',
      Label: t('Disabled'),
      className: 'text-right num no-wrap',
      Render: (row) => {
        return hasPermission(currentUser, permissionTypes.MANAGE_KEYS) ? (
          <NavLink
            to={`/keys/reported-disabled/?s_sku=${row.skuId}&s_companyid=${row.companyId}`}
          >
            {CommaNumber_NoDecimal(row.disabled)}
          </NavLink>
        ) : (
          CommaNumber_NoDecimal(row.disabled)
        );
      },
    },
    {
      Key: 'expired',
      Label: t('Expired'),
      className: 'text-right num no-wrap',
      Render: (row) => CommaNumber_NoDecimal(row.expired),
    },
  ];

  // filter
  const visibleColumns = tableSettings.columns ?? defaultVisibleColumns;
  const columns = visibleColumns
    .map((x) => columnsConfig.find((y) => y.Key === x))
    .filter((x) => x);

  // return an alaska table
  return items ? (
    <KwPanel className="mb-4">
      <Table
        className="table-v-stripes"
        columns={columns}
        allColumns={allColumns}
        defaultVisibleColumns={defaultVisibleColumns}
        rows={items.data.map((x, i) => {
          return {
            Key: `${i}${x.companyId}|${x.skuId}|${x.gameId}`,
            ...x,
          };
        })}
        totalRowCount={items.totalRowCount}
        filteredRowCount={items.filteredRowCount}
        tableSettings={tableSettings}
        setTableSettings={setTableSettings}
        isLoading={isLoading}
        filters={filters}
        filterChange={filterChange}
        exportData={exportKeysByPartnerByStatusPivot}
      />
    </KwPanel>
  ) : (
    <Loading></Loading>
  );
}

export default KeysByCompanyByStatusList;
