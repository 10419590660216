import * as reportApi from '../api/reportApi';

export function loadSLAsByCompanies({
  filters,
  _scopes,
  _sort,
  _order,
  _page,
  _limit,
}) {
  return reportApi.getSLAsByCompanies(
    filters,
    _scopes,
    _sort,
    _order,
    _page,
    _limit
  );
}

export function loadSLAsBySku({
  filters,
  _scopes,
  _sort,
  _order,
  _page,
  _limit,
}) {
  return reportApi.getSLAsBySku(filters, _scopes, _sort, _order, _page, _limit);
}

export function loadSLAsByWorkflows({
  filters,
  _scopes,
  _sort,
  _order,
  _page,
  _limit,
}) {
  return reportApi.getSLAsByWorkflows(
    filters,
    _scopes,
    _sort,
    _order,
    _page,
    _limit
  );
}

export function loadWorkflowsByRequesters({
  filters,
  _scopes,
  _sort,
  _order,
  _page,
  _limit,
}) {
  return reportApi.getWorkflowsByRequesters(
    filters,
    _scopes,
    _sort,
    _order,
    _page,
    _limit
  );
}

export function loadWorkflowsByApprovers({
  filters,
  _scopes,
  _sort,
  _order,
  _page,
  _limit,
}) {
  return reportApi.getWorkflowsByApprovers(
    filters,
    _scopes,
    _sort,
    _order,
    _page,
    _limit
  );
}

export function exportSLAsByCompany(filters, scopes) {
  return reportApi.exportSLAsByCompany(filters, scopes);
}

export function exportSLAsBySku(filters, scopes) {
  return reportApi.exportSLAsBySku(filters, scopes);
}

export function exportSLAsByWorkflows(filters, scopes) {
  return reportApi.exportSLAsByWorkflows(filters, scopes);
}

export function exportWorkflowsByApprovers(filters, scopes) {
  return reportApi.exportWorkflowsByApprovers(filters, scopes);
}

export function exportWorkflowsByRequesters(filters, scopes) {
  return reportApi.exportWorkflowsByRequesters(filters, scopes);
}

export function loadKeysByPartnerByStatusPivot({
  filters,
  _scopes,
  _sort,
  _order,
  _page,
  _limit,
}) {
  return reportApi.getKeysByPartnerByStatusPivot(
    filters,
    _scopes,
    _sort,
    _order,
    _page,
    _limit
  );
}

export function exportKeysByPartnerByStatusPivot(filters, scopes) {
  return reportApi.exportKeysByPartnerByStatusPivot(filters, scopes);
}
