import React from 'react';
import PropTypes from 'prop-types';
import KwPanel from '../../common/KwPanel';
import FormSelect from '../../common/FormSelect';
import FormTextArea from '../../common/FormTextArea';
import FormFileUpload from '../../common/FormFileUpload';
import FormCheckBoxList from '../../common/FormCheckBoxList';
import Button from '../../common/Button';
import ConfirmationHeader from '../../common/ConfirmationHeader';
import { useTranslation } from 'react-i18next';

function UploadDisabledForm({
  keyProviders,
  disableReasons,
  register,
  errors,
  control,
  submit,
  cancel,
  addFiles,
  removeFile,
  getValues,
  setValue,
  disabledFiles,
}) {
  const { t } = useTranslation();
  return (
    <>
      <KwPanel className="mb-4 pb-1">
        <FormSelect
          register={register}
          required
          errorMessage={t('Key provider is required')}
          error={errors.keyProviderId}
          name="keyProviderId"
          label={t('Key provider')}
          placeholder=""
          autoFocus={true}
        >
          <option value="">{t('--- choose ---')}</option>
          {keyProviders
            ? [...keyProviders]
                ?.sort((a, b) => a.name.localeCompare(b.name))
                .map((x) => (
                  <option key={x.id} value={x.id}>
                    {x.name}
                  </option>
                ))
            : ''}
        </FormSelect>
        <FormCheckBoxList
          label={t('Reason')}
          required
          name="disabledReasons"
          errorMessage={t('Required')}
          error={errors.reasons}
          getValues={getValues}
          setValue={setValue}
          control={control}
          items={
            disableReasons &&
            [...disableReasons]
              ?.sort((a, b) => a.reason.localeCompare(b.reason))
              .map((x) => ({ text: x.reason, value: x.id }))
          }
        ></FormCheckBoxList>

        <FormTextArea
          register={register}
          required
          error={errors.reason}
          errorMessage={t('Required')}
          name="reason"
          label={t('Please provide more information')}
          placeholder=""
        />
        <FormFileUpload
          name="files"
          required
          errorMessage="Required"
          label={t('Upload key file')}
          error={errors.files}
          addFiles={addFiles}
          removeFile={removeFile}
          files={disabledFiles}
        />
        <FormTextArea
          register={register}
          error={errors.description}
          name="description"
          label={t('Uploaded file description')}
          placeholder=""
        />
      </KwPanel>
      <KwPanel className="mb-4">
        <>
          <ConfirmationHeader />
          <p>
            {t(
              'Are you sure you want to queue these to be marked as disabled?'
            )}
          </p>
          <Button
            onClick={submit}
            className="btn btn-primary me-2"
            text={t('Yes, continue')}
          />
          <Button
            onClick={cancel}
            className="btn btn-default"
            text={t('No, cancel')}
          />
        </>
      </KwPanel>
    </>
  );
}
UploadDisabledForm.propTypes = {
  cancel: PropTypes.func.isRequired,
  keyProviders: PropTypes.array,
  disableReasons: PropTypes.array,
  register: PropTypes.func.isRequired,
  errors: PropTypes.object,
  control: PropTypes.object,
  submit: PropTypes.func.isRequired,
  addFiles: PropTypes.func.isRequired,
  removeFile: PropTypes.func.isRequired,
  getValues: PropTypes.func.isRequired,
  setValue: PropTypes.func.isRequired,
  disabledFiles: PropTypes.array.isRequired,
};

export default UploadDisabledForm;
