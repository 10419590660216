import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import SlideoutLayout from '../../layout/slideout/SlideoutLayout';

import { getKeyProviders } from '../../../actions/keyProviderActions';
import { useForm } from 'react-hook-form';
import PropTypes from 'prop-types';
import { bulkUpload } from '../../../actions/keyCodeActions';
import BulkIngestForm from './BulkIngestForm';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';

function BulkIngest({ done, cancel, getKeyProviders, keyProviders }) {
  const {
    register,
    formState: { errors },
    handleSubmit,
    control,
  } = useForm();
  const { t } = useTranslation();
  const [ingest, setIngest] = useState();
  const addFiles = (files) => {
    setIngest({ ...ingest, files: [...(ingest?.files ?? []), ...files] });
  };
  const onSubmit = (data) => {
    bulkUpload({ ...ingest, ...data })
      .then(() => {
        toast.success(t('Bulk key upload successfully'));
        done();
      })
      .catch((e) => {
        toast.error(e.message || t('Failed to upload bulk keys'));
      });
  };

  useEffect(() => {
    if (!keyProviders) {
      getKeyProviders();
    }
  }, [getKeyProviders, keyProviders]);

  return (
    <SlideoutLayout title={t('Bulk upload keys')} cancel={cancel} done={done}>
      <BulkIngestForm
        keyProviders={keyProviders}
        register={register}
        errors={errors}
        control={control}
        cancel={cancel}
        submit={handleSubmit(onSubmit)}
        addFiles={addFiles}
        files={ingest?.files}
      />
    </SlideoutLayout>
  );
}

function mapStateToProps(state, ownProps) {
  return {
    keyProviders: state.keyProviders,
  };
}

const mapDispatchToProps = {
  getKeyProviders,
};

BulkIngest.propTypes = {
  done: PropTypes.func.isRequired,
  cancel: PropTypes.func.isRequired,
  getKeyProviders: PropTypes.func,
  keyProviders: PropTypes.array,
};

export default connect(mapStateToProps, mapDispatchToProps)(BulkIngest);
