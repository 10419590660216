import React, { useState, useEffect } from 'react';

export const ToggleSwitch = (props) => {
  const { value: propValue, onChange, disabled, label } = props;
  const [value, setValue] = useState(propValue);

  const handleToggle = (e, newValue) => {
    if (!disabled) {
      onChange(newValue);
      setValue(newValue);
    }
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      handleToggle(event, !value);
    }
  };

  useEffect(() => {
    if (propValue !== undefined) {
      setValue(propValue);
    }
  }, [propValue]);

  return (
    <div
      onClick={(e) => handleToggle(e, !value)}
      aria-label={label}
      style={{
        display: 'inline-block',
        cursor: disabled ? 'not-allowed' : 'pointer',
      }}
    >
      <div
        style={{
          width: '40px',
          height: '22px',
          borderRadius: '11px',
          position: 'relative',
          transition: 'background-color 0.3s ease',
          backgroundColor: value ? '#16beef' : '#000',
          border: value ? '2px solid #16beef' : '2px solid #363c42',
        }}
        tabIndex="0"
        onKeyDown={handleKeyDown}
      >
        <div
          style={{
            width: '14px',
            height: '14px',
            backgroundColor: value ? '#111' : '#C2C2C5',
            borderRadius: '50%',
            position: 'absolute',
            top: '2px',
            left: value ? '22px' : '3px',
            transition: 'left 0.3s ease',
            boxShadow: '0 2px 4px rgba(0,0,0,0.2)',
          }}
        ></div>
      </div>
    </div>
  );
};
