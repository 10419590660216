import React, { useEffect } from 'react';
import KwPanel from '../../../common/KwPanel';
import PropTypes from 'prop-types';
import FormButtonGroup from '../../../common/FormButtonGroup';
import FormSelect from '../../../common/FormSelect';
import FormGroup from '../../../common/FormGroup';
import Search from '../../users/components/Search';
import { useTranslation } from 'react-i18next';

const UploaderForm = ({
  uploader,
  register,
  errors,
  control,
  watch,
  getValues,
  setValue,
  workflows,
  keyProviders,
  platforms,
  addingSpecific,
  groups,
  companies,
  allowedPublishers,
}) => {
  const { t } = useTranslation();
  const watchMode = watch('mode', uploader?.userProfileId ? 'User' : 'Group');
  const watchType = watch(
    'type',
    addingSpecific
      ? 'specific'
      : uploader?.keyProviderId
        ? 'Key provider'
        : uploader.platformId
          ? 'Platform'
          : 'Workflow'
  );

  useEffect(() => {
    setValue('userProfileId', uploader?.userProfileId);
  }, [uploader, setValue]);

  const handleOnModeChange = (btn) => {
    setValue('mode', btn.value);
    switch (btn.value) {
      case 'Group':
      default:
        clearFields(['userProfileId']);
        break;
      case 'User':
        clearFields(['userProfileGroupId']);
        break;
    }
  };

  const handleOnTypeChange = (btn) => {
    setValue('type', btn.value);
    switch (btn.value) {
      case 'Workflow':
      default:
        clearFields(['keyProviderId', 'platformId']);
        break;
      case 'Key provider':
        clearFields(['keyWorkflowId', 'platformId']);
        break;
      case 'Platform':
        clearFields(['keyWorkflowId', 'keyProviderId']);
        break;
    }
  };
  const clearFields = (fields) => {
    for (const field of fields) {
      setValue(field, null, {
        shouldDirty: true,
      });
    }
  };

  const handleUserSearchChange = (user) => {
    setValue('userProfileId', user[0].id);
  };

  const validateUser = () =>
    getValues('mode') === 'Group' || getValues('userProfileId') !== undefined;

  return (
    <>
      <KwPanel className="mb-4">
        <FormButtonGroup
          register={register}
          error={errors.type}
          errorMessage={t('Type is required')}
          name="mode"
          label={t('Type of uploader')}
          buttons={[
            { value: 'Group', text: t('Group') },
            { value: 'User', text: t('User') },
          ]}
          value={watchMode}
          onChange={handleOnModeChange}
        ></FormButtonGroup>
        {watchMode === 'Group' ? (
          <FormSelect
            register={register}
            required
            errorMessage={t('Required')}
            error={errors.userProfileGroupId}
            name="userProfileGroupId"
            label={t('Group')}
            placeholder=""
            defaultValue={uploader.userProfileGroupId || ''}
          >
            <option value="">{t('--- choose ---')}</option>
            {groups &&
              [...groups]
                ?.sort((a, b) => a.name.localeCompare(b.name))
                .map((x) => (
                  <option key={x.id} value={x.id}>
                    {x.name}
                  </option>
                ))}
          </FormSelect>
        ) : (
          watchMode === 'User' && (
            <FormGroup
              label={t('User')}
              error={errors.userProfileId}
              errorMessage={t('User is required')}
              required
            >
              <Search
                onChange={handleUserSearchChange}
                defaultValue={uploader?.fullName}
              ></Search>
            </FormGroup>
          )
        )}
        <FormSelect
          register={register}
          error={errors.companyId}
          name="companyId"
          label={t('Company')}
          placeholder=""
          defaultValue={uploader.companyId || ''}
        >
          <option value="">{t('--- choose ---')}</option>
          {companies &&
            allowedPublishers &&
            [...companies]
              .filter(
                (x) =>
                  x.isPublisher && allowedPublishers?.some((y) => y === x.id)
              )
              ?.sort((a, b) => a.companyName.localeCompare(b.companyName))
              ?.map((x) => (
                <option key={x.id} value={x.id}>
                  {x.companyName}
                </option>
              ))}
        </FormSelect>
      </KwPanel>

      {!addingSpecific && (
        <KwPanel className="mb-4">
          <header className="mb-2">
            <h2 className="no-margin">{t('Allow upload keys for...')}</h2>
          </header>
          <FormButtonGroup
            register={register}
            error={errors.type}
            errorMessage={t('Type is required')}
            name="type"
            label={t('Type')}
            buttons={[
              { value: 'Workflow', text: t('Workflow') },
              { value: 'Key provider', text: t('Key provider') },
              { value: 'Platform', text: t('Platform') },
            ]}
            onChange={handleOnTypeChange}
            value={watchType}
          ></FormButtonGroup>
          {watchType === 'Workflow' && (
            <FormSelect
              register={register}
              required
              error={errors.keyWorkflowId}
              errorMessage={t('Workflow is required')}
              name="keyWorkflowId"
              label={t('Workflow')}
              defaultValue={uploader.keyWorkflowId || ''}
            >
              <option value="">{t('--- choose ---')}</option>
              {workflows &&
                [...workflows]
                  ?.sort((a, b) => a.name.localeCompare(b.name))
                  .map((x) => (
                    <option key={x.id} value={x.id}>
                      {x.name}
                    </option>
                  ))}
            </FormSelect>
          )}
          {watchType === 'Key provider' && (
            <FormSelect
              register={register}
              required
              error={errors.keyProviderId}
              errorMessage={t('Key provider is required')}
              name="keyProviderId"
              label={t('Key provider')}
              defaultValue={uploader.keyProviderId || ''}
            >
              <option value="">{t('--- choose ---')}</option>
              {keyProviders &&
                [...keyProviders]
                  ?.sort((a, b) => a.name.localeCompare(b.name))
                  .map((x) => (
                    <option key={x.id} value={x.id}>
                      {x.name}
                    </option>
                  ))}
            </FormSelect>
          )}
          {watchType === 'Platform' && (
            <FormSelect
              register={register}
              required
              error={errors.platformId}
              errorMessage={t('Platform is required')}
              name="platformId"
              label={t('Platform')}
              defaultValue={uploader.platformId || ''}
            >
              <option value="">{t('--- choose ---')}</option>
              {platforms &&
                [...platforms]
                  ?.sort((a, b) => a.name.localeCompare(b.name))
                  .map((x) => (
                    <option key={x.id} value={x.id}>
                      {x.name}
                    </option>
                  ))}
            </FormSelect>
          )}
        </KwPanel>
      )}
    </>
  );
};

UploaderForm.propTypes = {
  uploader: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  register: PropTypes.func.isRequired,
};

export default UploaderForm;
