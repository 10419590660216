import { apiUrl, exportData, getList } from './apiUtils';
const baseUrl = apiUrl + '/reports/';
const exportBaseUrl = `${apiUrl}/export/reports/`;

export function getSLAsByCompanies(
  filters,
  _scopes,
  _sort,
  _order,
  _page,
  _limit
) {
  return getList(
    `${baseUrl}sla/company`,
    filters,
    _scopes,
    _sort,
    _order,
    _page,
    _limit
  );
}

export function getSLAsBySku(filters, _scopes, _sort, _order, _page, _limit) {
  return getList(
    `${baseUrl}sla/sku`,
    filters,
    _scopes,
    _sort,
    _order,
    _page,
    _limit
  );
}

export function getSLAsByWorkflows(
  filters,
  _scopes,
  _sort,
  _order,
  _page,
  _limit
) {
  return getList(
    `${baseUrl}sla/workflow`,
    filters,
    _scopes,
    _sort,
    _order,
    _page,
    _limit
  );
}

export function getWorkflowsByRequesters(
  filters,
  _scopes,
  _sort,
  _order,
  _page,
  _limit
) {
  return getList(
    `${baseUrl}workflows/requesters`,
    filters,
    _scopes,
    _sort,
    _order,
    _page,
    _limit
  );
}

export function getWorkflowsByApprovers(
  filters,
  _scopes,
  _sort,
  _order,
  _page,
  _limit
) {
  return getList(
    `${baseUrl}workflows/approvers`,
    filters,
    _scopes,
    _sort,
    _order,
    _page,
    _limit
  );
}

export function exportSLAsByCompany(filters, scopes) {
  return exportData(
    `${exportBaseUrl}sla/company`,
    'companyName',
    filters,
    scopes
  );
}

export function exportSLAsBySku(filters, scopes) {
  return exportData(`${exportBaseUrl}sla/sku`, 'gameName', filters, scopes);
}

export function exportSLAsByWorkflows(filters, scopes) {
  return exportData(`${exportBaseUrl}sla/workflow`, 'name', filters, scopes);
}

export function exportWorkflowsByRequesters(filters, scopes) {
  return exportData(
    `${exportBaseUrl}workflows/requesters`,
    'keyWorkflowName',
    filters,
    scopes
  );
}

export function exportWorkflowsByApprovers(filters, scopes) {
  return exportData(
    `${exportBaseUrl}workflows/approvers`,
    'keyWorkflowName',
    filters,
    scopes
  );
}

export function getKeysByPartnerByStatusPivot(
  filters,
  _scopes,
  _sort,
  _order,
  _page,
  _limit
) {
  return getList(
    `${baseUrl}keys/company/pivot`,
    filters,
    _scopes,
    _sort,
    _order,
    _page,
    _limit
  );
}

export function exportKeysByPartnerByStatusPivot(filters, scopes) {
  return exportData(
    `${exportBaseUrl}keys/company/pivot`,
    'companyName',
    filters,
    scopes
  );
}
