import React from 'react';
import PropTypes from 'prop-types';
import KwPanel from '../../common/KwPanel';
import FormSelect from '../../common/FormSelect';
import FormTextArea from '../../common/FormTextArea';
import FormFileUpload from '../../common/FormFileUpload';
import Button from '../../common/Button';
import ConfirmationHeader from '../../common/ConfirmationHeader';
import { useTranslation } from 'react-i18next';

function UploadSoldForm({
  keyProviders,
  register,
  errors,
  submit,
  cancel,
  addFiles,
  removeFile,
  files,
}) {
  const { t } = useTranslation();
  return (
    <>
      <KwPanel className="mb-4 pb-1">
        <FormSelect
          register={register}
          errorMessage={t('Key provider is required')}
          error={errors.keyProviderId}
          name="keyProviderId"
          label={t('Key provider')}
          required={true}
          placeholder=""
          autoFocus={true}
        >
          <option value="">{t('--- choose ---')}</option>
          {keyProviders
            ? [...keyProviders]
                ?.sort((a, b) => a.name.localeCompare(b.name))
                .map((x) => (
                  <option key={x.id} value={x.id}>
                    {x.name}
                  </option>
                ))
            : ''}
        </FormSelect>
        <FormFileUpload
          name="files"
          label={t('Upload key file')}
          error={errors.files}
          required={true}
          addFiles={addFiles}
          removeFile={removeFile}
          files={files}
        />
        <FormTextArea
          register={register}
          error={errors.register}
          name="description"
          label={t('Uploaded file description')}
          placeholder=""
        />
      </KwPanel>
      <KwPanel className="mb-4">
        <>
          <ConfirmationHeader />
          <p>{t('Are you sure?')}</p>
          <Button
            onClick={submit}
            className="btn btn-primary me-2"
            text={t('Yes, continue')}
          />
          <Button
            onClick={cancel}
            className="btn btn-default"
            text={t('No, cancel')}
          />
        </>
      </KwPanel>
    </>
  );
}
UploadSoldForm.propTypes = {
  keyProviders: PropTypes.array,
  register: PropTypes.func.isRequired,
  errors: PropTypes.object.isRequired,
  submit: PropTypes.func.isRequired,
  cancel: PropTypes.func.isRequired,
  addFiles: PropTypes.func.isRequired,
  removeFile: PropTypes.func.isRequired,
  files: PropTypes.array.isRequired,
};
export default UploadSoldForm;
