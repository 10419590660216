import React from 'react';
import { NavLink } from 'react-router-dom';
import { isPowerUser } from '../../auth/authUtils';

function SKULink({
  skuId,
  publisherCompanyId,
  skuName,
  className,
  currentUser,
  onClick,
}) {
  const handleOnClick = () => {
    if (typeof onClick === 'function') {
      onClick();
    }
  };

  return (
    <div className={className}>
      {currentUser && isPowerUser(currentUser) ? (
        <>
          {skuId ? (
            <>
              <NavLink to={`/sku/${skuId}`} onClick={handleOnClick}>
                {skuName}
              </NavLink>
            </>
          ) : (
            <>{skuName}</>
          )}
        </>
      ) : (
        <>{skuName}</>
      )}
    </div>
  );
}
export default SKULink;
