import React, { useState, useEffect } from 'react';
import { getAssignableUsers } from '../../../actions/userActions';
import { reassignRequest } from '../../../actions/requestActions';
import FormSelect from '../../common/FormSelect';
import FormStatic from '../../common/FormStatic';
import KwPanel from '../../common/KwPanel';
import SlideoutLayout from '../../layout/slideout/SlideoutLayout';
import { toast } from 'react-toastify';
import Button from '../../common/Button';
import { useTranslation } from 'react-i18next';

export default function Reassign({ done, cancel, request, history }) {
  const [userId, setUserId] = useState();
  const [users, setUsers] = useState();
  const [processing, setProcessing] = useState();
  const { t } = useTranslation();

  const onUserChange = (e) => {
    const id = e.target.value;
    setUserId(id);
  };

  useEffect(() => {
    getAssignableUsers()
      .then((d) => {
        setUsers(d);
      })
      .catch((e) => {
        console.error(e);
        toast.error(t('No users found'));
      });
  }, [history]);

  const onSubmit = (data) => {
    setProcessing(true);
    reassignRequest(request.id, userId || null)
      .then((d) => {
        if (d.assignedToUserProfileId) {
          toast.success(t('Request reassigned'));
        } else {
          toast.success(t('Request unassigned'));
        }
        done(d);
        setProcessing(false);
      })
      .catch((e) => {
        toast.error(e.message || t('Failed to reassign request'));
        setProcessing(false);
      });
  };

  return (
    <SlideoutLayout
      title={t('Reassign')}
      cancel={cancel}
      done={done}
      bar={
        <Button
          className="btn btn-primary"
          onClick={onSubmit}
          isDisabled={processing}
          title={processing ? t('Processing') : t('Assign')}
          text={t('Assign')}
        />
      }
    >
      <KwPanel className="mb-4 form-horizontal">
        <FormStatic label={t('Currently assigned to')}>
          {request.assignedToUserProfileFullName || t('Unassigned')}
        </FormStatic>
        <FormSelect
          label={t('Assign to')}
          placeholder=""
          onChange={onUserChange}
        >
          <option value={null}>{t('--- unassign ---')}</option>
          {users?.map((x) => (
            <option key={x.id} value={x.id}>
              {x.fullName}
            </option>
          ))}
        </FormSelect>
      </KwPanel>
    </SlideoutLayout>
  );
}
