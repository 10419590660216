import React, { useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { useLocalStorage } from '../../../hooks/useLocalStorage';
import { downloadKeys } from '../../../actions/requestActions';
import BulkActions from '../slideout/BulkActions';
import SlidingPane from 'react-sliding-pane';
import UploadKeys from '../slideout/UploadKeys';
import { addToGroup, removeFromGroup } from '../../../actions/signalRActions';
import useUploadNotificationsSingle from '../../../hooks/useUploadNotificationsSingle';
import { toast } from 'react-toastify';
import SteamBulkRequest from '../slideout/SteamBulkRequest';
import ReassignBulk from '../slideout/ReassignBulk';
import ChangeStatusBulk from '../slideout/ChangeStatusBulk';
import ReturnKeys from '../slideout/ReturnKeys';
import RequestsTableForUserTypeAndDisplayMode from './RequestLists/RequestsTableForUserTypeAndDisplayMode';
import { useTranslation } from 'react-i18next';

function RequestsTable({
  scope,
  settingsKey,
  title,
  reload,
  setReload,
  location,
  history,
  currentUser,
  addToGroup,
  removeFromGroup,
  importTransactions,
  setCompleteRequestSlideoutOpen,
  setBreadcrumb,
}) {
  const { t } = useTranslation();
  const [isProcessing, setIsProcessing] = useState(false);
  const [itemsChecked, setItemsChecked] = useState([]);
  const [bulkActionsSlideoutOpen, setBulkActionsSlideoutOpen] = useState(false);
  const [uploadKeysSlideoutItem, setUploadKeysSlideoutItem] = useState();
  const [uploadKeysSlideoutOpen, setUploadKeysSlideoutOpen] = useState(false);
  const [requestsGrouped, setRequestsGrouped] = useLocalStorage(
    'requestsGrouped',
    false
  );
  const found = (foundTransactions) => {
    if (foundTransactions.length === 1) {
      const transaction = foundTransactions.slice(-1)[0];
      if (transaction.success === true) {
        toast.success(transaction.message);
        setUploadsInProgress((prevState) =>
          prevState.filter((id) => id !== transaction.id)
        );
        setReload(new Date());
      } else if (transaction.success === false) {
        toast.error(transaction.message);
        setUploadsInProgress((prevState) =>
          prevState.filter((id) => id !== transaction.id)
        );
        setReload(new Date());
      }
    }
  };
  const [uploadsInProgress, setUploadsInProgress] =
    useUploadNotificationsSingle(
      'import',
      removeFromGroup,
      importTransactions,
      found
    );

  const handleDownloadKeys = (e, request) => {
    e.cancelBubble = true;
    setIsProcessing(true);
    downloadKeys(request.id)
      .then(() => {
        setIsProcessing(false);
      })
      .catch((e) => {
        toast.error(e.message || t('Failed to download keys'));
        setIsProcessing(false);
      });
  };

  const handleUploadKeys = (e, item) => {
    if (e) {
      e.cancelBubble = true;
    }
    setUploadKeysSlideoutItem(item);
    setUploadKeysSlideoutOpen(true);
    return false;
  };

  const [bulkStatusChangeSlideoutOpen, setBulkStatusChangeSlideoutOpen] =
    useState(false);
  const [
    bulkStatusChangeSlideoutRequests,
    setBulkStatusChangeSlideoutRequests,
  ] = useState();

  const [bulkReassignSlideoutOpen, setBulkReassignSlideoutOpen] =
    useState(false);
  const [bulkReassignSlideoutRequests, setBulkReassignSlideoutRequests] =
    useState();

  const [bulkSteamRequestSlideoutOpen, setBulkSteamRequestSlideoutOpen] =
    useState(false);
  const [bulkSteamRequestSlideoutSKUs, setBulkSteamRequestSlideoutSKUs] =
    useState();

  const handleBulkStatusChange = (e, row) => {
    setBulkStatusChangeSlideoutRequests([row.requestId ?? row.id]);
    setBulkStatusChangeSlideoutOpen(true);
  };

  const handleBulkReassign = (e, row) => {
    setBulkReassignSlideoutRequests([row.id]);
    setBulkReassignSlideoutOpen(true);
  };

  const handleBulkSteamRequest = (e, row) => {
    let skus = [];
    if (row.games) {
      for (const j in row.games) {
        const requestRow = row.games[j];
        if (requestRow.canOrderKeys) {
          skus = addSteamRequestItem(requestRow, requestRow.requestId, skus);
        }
      }
    } else if (row.canOrderKeys) {
      skus = addSteamRequestItem(row, row.id, skus);
    }
    if (skus.length > 0) {
      setBulkSteamRequestSlideoutSKUs(skus);
      setBulkSteamRequestSlideoutOpen(true);
    } else {
      toast.error(
        t('None of the selected Key Requests are in the correct state')
      );
    }
  };

  const [returnKeysSlideoutOpen, setReturnKeysSlideoutOpen] = useState(false);
  const [returnKeysSlideoutItem, setReturnKeysSlideoutItem] = useState();
  const handleReturnKeys = (e, item) => {
    e.cancelBubble = true;
    setReturnKeysSlideoutOpen(true);
    setReturnKeysSlideoutItem(item);
    return false;
  };

  const addSteamRequestItem = (row, keyRequestId, skus) => {
    const keyPoolRow = {
      keyWorkflowId: row.keyWorkflowId,
      keyWorkflowName: row.keyWorkflowName,
      keyProviderReference: row.keyProviderReference,
      numberOfKeys: row.numberOfKeys,
    };
    skus.push({
      id: row.skuId,
      gameName: row.gameTitle,
      name: row.skuName,
      keyRequestId: keyRequestId,
      packageTypeId: row.packageTypeId,
      keyPools: [keyPoolRow],
    });
    return skus;
  };

  return (
    <>
      <RequestsTableForUserTypeAndDisplayMode
        title={title}
        location={location}
        history={history}
        reload={reload}
        itemsChecked={itemsChecked}
        setItemsChecked={setItemsChecked}
        requestsGrouped={requestsGrouped}
        setRequestsGrouped={setRequestsGrouped}
        currentUser={currentUser}
        setBulkActionsSlideoutOpen={setBulkActionsSlideoutOpen}
        setCompleteRequestSlideoutOpen={setCompleteRequestSlideoutOpen}
        setBreadcrumb={setBreadcrumb}
        scope={scope}
        settingsKey={settingsKey}
        handleDownloadKeys={handleDownloadKeys}
        handleUploadKeys={handleUploadKeys}
        handleBulkStatusChange={handleBulkStatusChange}
        handleBulkReassign={handleBulkReassign}
        handleBulkSteamRequest={handleBulkSteamRequest}
        handleReturnKeys={handleReturnKeys}
        isProcessing={isProcessing}
      />
      <SlidingPane
        isOpen={bulkActionsSlideoutOpen}
        hideHeader={true}
        from="right"
        className="small-side-panel"
        onRequestClose={() => setBulkActionsSlideoutOpen(false)}
      >
        <BulkActions
          done={() => {
            setReload(new Date());
            setItemsChecked([]);
            setBulkActionsSlideoutOpen(false);
          }}
          cancel={() => setBulkActionsSlideoutOpen(false)}
          items={itemsChecked}
        ></BulkActions>
      </SlidingPane>

      <SlidingPane
        isOpen={uploadKeysSlideoutOpen}
        hideHeader={true}
        from="right"
        className="side-panel"
        onRequestClose={() => setUploadKeysSlideoutOpen(false)}
      >
        <>
          {uploadKeysSlideoutItem && (
            <UploadKeys
              done={(d) => {
                if (!uploadsInProgress.includes(d.id)) {
                  addToGroup(`import:${d.id}`);
                  setUploadsInProgress((prevState) =>
                    prevState.includes(d.id) ? prevState : [...prevState, d.id]
                  );
                }
                setReload(new Date());
                setUploadKeysSlideoutOpen(false);
              }}
              cancel={() => setUploadKeysSlideoutOpen(false)}
              requestId={uploadKeysSlideoutItem?.id}
              gameName={uploadKeysSlideoutItem?.gameTitle}
              skuName={uploadKeysSlideoutItem?.skuName}
              keyProviderName={uploadKeysSlideoutItem?.keyProviderName}
              packageId={uploadKeysSlideoutItem?.packageId}
              numberOfKeys={uploadKeysSlideoutItem?.numberOfKeys}
              file={uploadKeysSlideoutItem?.file}
            ></UploadKeys>
          )}
        </>
      </SlidingPane>

      <SlidingPane
        isOpen={bulkStatusChangeSlideoutOpen}
        hideHeader={true}
        from="right"
        className="small-side-panel"
        onRequestClose={() => setBulkStatusChangeSlideoutOpen(false)}
      >
        <ChangeStatusBulk
          done={() => {
            setReload(new Date());
            setBulkStatusChangeSlideoutOpen(false);
          }}
          cancel={() => setBulkStatusChangeSlideoutOpen(false)}
          requests={bulkStatusChangeSlideoutRequests}
        ></ChangeStatusBulk>
      </SlidingPane>

      <SlidingPane
        isOpen={bulkReassignSlideoutOpen}
        hideHeader={true}
        from="right"
        className="side-panel"
        onRequestClose={() => setBulkReassignSlideoutOpen(false)}
      >
        <ReassignBulk
          done={() => {
            setReload(new Date());
            setBulkReassignSlideoutOpen(false);
          }}
          cancel={() => setBulkReassignSlideoutOpen(false)}
          requests={bulkReassignSlideoutRequests}
        ></ReassignBulk>
      </SlidingPane>

      <SlidingPane
        isOpen={bulkSteamRequestSlideoutOpen}
        hideHeader={true}
        from="right"
        className="large-side-panel"
        onRequestClose={() => setBulkSteamRequestSlideoutOpen(false)}
      >
        <SteamBulkRequest
          done={() => {
            setReload(new Date());
            setBulkSteamRequestSlideoutOpen(false);
          }}
          cancel={() => setBulkSteamRequestSlideoutOpen(false)}
          skus={bulkSteamRequestSlideoutSKUs}
        ></SteamBulkRequest>
      </SlidingPane>

      <SlidingPane
        isOpen={returnKeysSlideoutOpen}
        hideHeader={true}
        from="right"
        className="side-panel"
        onRequestClose={() => setReturnKeysSlideoutOpen(false)}
      >
        <ReturnKeys
          request={returnKeysSlideoutItem}
          done={() => {
            setReturnKeysSlideoutOpen(false);
            setReload(new Date());
          }}
          cancel={() => setReturnKeysSlideoutOpen(false)}
        ></ReturnKeys>
      </SlidingPane>
    </>
  );
}

function mapStateToProps(state, ownProps) {
  return {
    location: ownProps.location,
    currentUser: state.auth,
    importTransactions: state.importTransactions,
  };
}

const mapDispatchToProps = {
  addToGroup,
  removeFromGroup,
};

RequestsTable.propTypes = {
  scope: PropTypes.object,
  settingsKey: PropTypes.string,
  title: PropTypes.string,
  reload: PropTypes.object,
  setReload: PropTypes.func,
  location: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  currentUser: PropTypes.object.isRequired,
  addToGroup: PropTypes.func.isRequired,
  removeFromGroup: PropTypes.func.isRequired,
  importTransactions: PropTypes.array,
  setCompleteRequestSlideoutOpen: PropTypes.func.isRequired,
  setBreadcrumb: PropTypes.func,
};

export default connect(mapStateToProps, mapDispatchToProps)(RequestsTable);
