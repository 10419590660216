import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import SlideoutLayout from '../../layout/slideout/SlideoutLayout';
import { getKeyProviders } from '../../../actions/keyProviderActions';
import { useForm } from 'react-hook-form';
import PropTypes from 'prop-types';
import { uploadSold } from '../../../actions/keyCodeActions';
import UploadSoldForm from './UploadSoldForm';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';

function UploadSold({ done, cancel, getKeyProviders, keyProviders }) {
  const { t } = useTranslation();
  const {
    register,
    formState: { errors },
    handleSubmit,
    control,
  } = useForm();
  const [soldFiles, setSoldFiles] = useState([]);
  const addFiles = (files) => {
    setSoldFiles((prevFiles) => [...prevFiles, ...files]);
  };
  const removeFile = (file) => {
    setSoldFiles((prevFiles) =>
      prevFiles.filter((prevFile) => prevFile !== file)
    ); // Remove file from the list
  };
  const onSubmit = (data) => {
    Promise.all(soldFiles.map((file) => uploadSold({ file, ...data })))
      .then(() => {
        toast.success(t('Sold keys successfully uploaded'));
        done();
      })
      .catch((e) => {
        toast.error(e.message || t('Failed to upload sold keys'));
      });
  };

  useEffect(() => {
    if (!keyProviders) {
      getKeyProviders();
    }
  }, [getKeyProviders, keyProviders]);

  return (
    <SlideoutLayout title={t('Upload sold keys')} cancel={cancel} done={done}>
      <UploadSoldForm
        keyProviders={keyProviders}
        register={register}
        errors={errors}
        control={control}
        cancel={cancel}
        submit={handleSubmit(onSubmit)}
        addFiles={addFiles}
        removeFile={removeFile}
        files={soldFiles}
      />
    </SlideoutLayout>
  );
}

function mapStateToProps(state, ownProps) {
  return {
    keyProviders: state.keyProviders,
  };
}

const mapDispatchToProps = {
  getKeyProviders,
};

UploadSold.propTypes = {
  done: PropTypes.func.isRequired,
  cancel: PropTypes.func.isRequired,
  getKeyProviders: PropTypes.func.isRequired,
  keyProviders: PropTypes.array,
};

export default connect(mapStateToProps, mapDispatchToProps)(UploadSold);
