import React from 'react';
import KwPanel from '../../../common/KwPanel';
import FormInput from '../../../common/FormInput';
import PropTypes from 'prop-types';
import FormToggleBool from '../../../common/FormToggleBool';
import { useTranslation } from 'react-i18next';

const CloneWorkflowForm = ({ register, errors, control }) => {
  const { t } = useTranslation();
  return (
    <KwPanel className="mb-4">
      <FormInput
        register={register}
        required={true}
        error={errors?.name}
        errorMessage={t('Name is required')}
        name="name"
        label={t('Name')}
        placeholder={t('Name of the workflow')}
        autoFocus={true}
      />
      <FormToggleBool
        control={control}
        name="withRestrictions"
        label={t('Clone restrictions?')}
        defaultValue={false}
      />
    </KwPanel>
  );
};

CloneWorkflowForm.propTypes = {
  errors: PropTypes.object,
  register: PropTypes.func.isRequired,
};

export default CloneWorkflowForm;
