import React, { useEffect } from 'react';
import { connect } from 'react-redux';

import PropTypes from 'prop-types';
import KwPanel from '../../common/KwPanel';

import GamesCompressedView from './GamesCompressedView';
import GamesUncompressedView from './GamesUncompressedView';
import { useLocalStorage } from '../../../hooks/useLocalStorage';
import { debounce } from '../../../util/debounceUtils';
import icons from '../../../constants/icons';
import { hasPermission, isPowerUser } from '../../auth/authUtils';
import permissionTypes from '../../../constants/permissionTypes';
import Button from '../../common/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';

Games.propTypes = {
  requests: PropTypes.array.isRequired,
  processing: PropTypes.bool,
  onApproveAll: PropTypes.func.isRequired,
  onDeclineAll: PropTypes.func.isRequired,
  onApproveItem: PropTypes.func.isRequired,
  onDeclineItem: PropTypes.func.isRequired,
  onWithdrawRequest: PropTypes.func.isRequired,
  onDownloadKeys: PropTypes.func.isRequired,
  onUploadKeys: PropTypes.func.isRequired,
  onKeysOrdered: PropTypes.func.isRequired,
  onSteamRequest: PropTypes.func.isRequired,
  onSetStatus: PropTypes.func.isRequired,
  onShowDetails: PropTypes.func.isRequired,
  onEditRequestValue: PropTypes.func.isRequired,
  onEditRequestKeyCount: PropTypes.func.isRequired,
  onDisableKeys: PropTypes.func.isRequired,
  onReassign: PropTypes.func.isRequired,
  searchFilter: PropTypes.string.isRequired,
  onSearchFilterChange: PropTypes.func.isRequired,
  endUserDetailsRequired: PropTypes.bool.isRequired,
  handleUploadAccountDetails: PropTypes.func.isRequired,
  handleReturnKeys: PropTypes.func.isRequired,
  currentUser: PropTypes.object.isRequired,
};

function Games({
  requests,
  processing,
  onApproveAll,
  onDeclineAll,
  onApproveItem,
  onDeclineItem,
  onWithdrawRequest,
  onDownloadKeys,
  onUploadKeys,
  onKeysOrdered,
  onSteamRequest,
  onSetStatus,
  onShowDetails,
  onEditRequestValue,
  onEditRequestKeyCount,
  onDisableKeys,
  onReassign,
  searchFilter,
  onSearchFilterChange,
  endUserDetailsRequired,
  handleUploadAccountDetails,
  handleReturnKeys,
  currentUser,
}) {
  const { t } = useTranslation('requests');
  const [compressed, setCompressed] = useLocalStorage(
    'keyRequestCompressedView',
    false
  );

  useEffect(() => {
    const debouncedHandleResize = debounce(function handleResize() {
      if (window.innerWidth < 991 && compressed) {
        setCompressed(false);
      }
    }, 1000);

    window.addEventListener('resize', debouncedHandleResize);

    return (_) => {
      window.removeEventListener('resize', debouncedHandleResize);
    };
  });

  const handleSearchFilterChange = (el) => {
    onSearchFilterChange(el.target.value);
  };

  const allRequestsVisible = () => {
    return (
      requests.filter(
        (request) =>
          request.requestStatus?.isPending ||
          request.requestStatus?.isKeysOrdered
      ).length > 0
    );
  };
  const howManyCanBeSigned = () => {
    return requests.filter((request) => request.currentUserCanSign).length;
  };
  const areAllRequestsApproved = () => {
    let approvedRequests = requests.filter(
      (request) => request.action === 'approve' && request.currentUserCanSign
    ).length;
    let b = howManyCanBeSigned();
    return approvedRequests === b && b > 0;
  };

  const areAllRequestsDeclined = () => {
    let declinedRequests = requests.filter(
      (request) => request.action === 'decline' && request.currentUserCanSign
    ).length;
    let b = howManyCanBeSigned();
    return declinedRequests === b && b > 0;
  };

  // ================================================================================
  // Button display logic
  let showAllButtons = false; // set to true to see all buttons in dev

  const canDisableKeys = (request) => {
    return (
      !request.isWaitingForKeys &&
      request.requestStatus.isAuthorised &&
      hasPermission(currentUser, permissionTypes.MANAGE_KEYS)
    );
  };

  const canAssignRequest = (request) => {
    return isPowerUser(currentUser);
  };

  const canDownloadKeys = (request) => {
    return showAllButtons || request.allowDownloadKeys;
  };

  const canUploadKeys = (request) => {
    return showAllButtons || request.allowUploadKeys;
  };

  const canSetKeysOrdered = (request) => {
    return (
      showAllButtons ||
      (request.allowUploadKeys && !request.requestStatus.isKeysOrdered)
    );
  };

  const canRequestOnSteam = (request) => {
    return (
      showAllButtons ||
      (request.allowUploadKeys && request.keyProviderReference)
    );
  };

  const canSetStatus = (request) => {
    return showAllButtons || request.allowChangeStatus;
  };

  const canWithdrawRequest = (request) => {
    return showAllButtons || request.allowWithdrawRequest;
  };

  const canReturnKeys = (request) => {
    return showAllButtons || request.allowReturnKeys;
  };

  return (
    <KwPanel className="mb-4">
      <div className="space-bottom space-row-sm space-row-even">
        <div className="space-bottom-xs d-flex" style={{ overflow: 'hidden' }}>
          <div className="me-2 d-none d-md-flex ">
            <button
              className={`btn ${
                compressed
                  ? 'btn-default btn-outline btn--square'
                  : 'btn-primary btn-outline btn--square'
              } r-corners-left`}
              onClick={() => setCompressed(false)}
            >
              <FontAwesomeIcon
                icon={icons.TABLE_GROUPED}
                fixedWidth
                aria-hidden="true"
              />
            </button>
            <button
              className={`btn ${
                compressed
                  ? 'btn-primary btn-outline btn--square'
                  : 'btn-default btn-outline btn--square'
              } r-corners-right`}
              onClick={() => setCompressed(true)}
            >
              <FontAwesomeIcon
                icon={icons.TABLE_UNGROUPED}
                fixedWidth
                aria-hidden="true"
              />
            </button>
          </div>
          <div
            className="search-wrapper in-panel"
            style={{ width: 'calc(100% - 44px)' }}
          >
            <input
              type="text"
              className="form-control r-corners-left"
              placeholder={t('Search titles')}
              value={searchFilter}
              onChange={handleSearchFilterChange}
            />

            <div className="search-icon">
              <FontAwesomeIcon icon={icons.SEARCH} />
            </div>
          </div>
        </div>
        <div className="text-right space-row-xs space-row-even">
          {allRequestsVisible() && howManyCanBeSigned() > 1 && (
            <div className="btn-group me-md-4">
              <Button
                isDisabled={processing}
                title={processing ? t('Processing') : t('Approve')}
                className={`btn btn-default btn--approval space-right-s-xs ${
                  areAllRequestsApproved() ? 'active' : ''
                }`}
                onClick={onApproveAll}
                icon={icons.APPROVE}
                hideTitle={true}
                text={t('Approve')}
              />
              <Button
                isDisabled={processing}
                title={processing ? t('Processing') : t('Decline')}
                className={`btn btn-default btn--decline space-left-s-xs${
                  areAllRequestsDeclined() ? 'active' : ''
                }`}
                onClick={onDeclineAll}
                icon={icons.DECLINE}
                hideTitle={true}
                text={t('Decline')}
              />
            </div>
          )}
        </div>
      </div>

      {compressed ? (
        <GamesCompressedView
          requests={requests}
          canWithdrawRequest={canWithdrawRequest}
          processing={processing}
          onWithdrawRequest={onWithdrawRequest}
          canSetStatus={canSetStatus}
          onSetStatus={onSetStatus}
          canDownloadKeys={canDownloadKeys}
          onDownloadKeys={onDownloadKeys}
          canRequestOnSteam={canRequestOnSteam}
          onSteamRequest={onSteamRequest}
          canUploadKeys={canUploadKeys}
          onUploadKeys={onUploadKeys}
          canSetKeysOrdered={canSetKeysOrdered}
          onKeysOrdered={onKeysOrdered}
          onShowDetails={onShowDetails}
          onApproveItem={onApproveItem}
          onDeclineItem={onDeclineItem}
          onEditRequestKeyCount={onEditRequestKeyCount}
          onEditRequestValue={onEditRequestValue}
          canDisableKeys={canDisableKeys}
          onDisableKeys={onDisableKeys}
          canAssignRequest={canAssignRequest}
          onReassign={onReassign}
          showAllButtons={showAllButtons}
          endUserDetailsRequired={endUserDetailsRequired}
          handleUploadAccountDetails={handleUploadAccountDetails}
          canReturnKeys={canReturnKeys}
          handleReturnKeys={handleReturnKeys}
          currentUser={currentUser}
        ></GamesCompressedView>
      ) : (
        <GamesUncompressedView
          requests={requests}
          canWithdrawRequest={canWithdrawRequest}
          processing={processing}
          onWithdrawRequest={onWithdrawRequest}
          canSetStatus={canSetStatus}
          onSetStatus={onSetStatus}
          canDownloadKeys={canDownloadKeys}
          onDownloadKeys={onDownloadKeys}
          canRequestOnSteam={canRequestOnSteam}
          onSteamRequest={onSteamRequest}
          canUploadKeys={canUploadKeys}
          onUploadKeys={onUploadKeys}
          canSetKeysOrdered={canSetKeysOrdered}
          onKeysOrdered={onKeysOrdered}
          onShowDetails={onShowDetails}
          onApproveItem={onApproveItem}
          onDeclineItem={onDeclineItem}
          onEditRequestKeyCount={onEditRequestKeyCount}
          onEditRequestValue={onEditRequestValue}
          onDisableKeys={onDisableKeys}
          canDisableKeys={canDisableKeys}
          canAssignRequest={canAssignRequest}
          onReassign={onReassign}
          showAllButtons={showAllButtons}
          endUserDetailsRequired={endUserDetailsRequired}
          handleUploadAccountDetails={handleUploadAccountDetails}
          canReturnKeys={canReturnKeys}
          handleReturnKeys={handleReturnKeys}
          currentUser={currentUser}
        ></GamesUncompressedView>
      )}
    </KwPanel>
  );
}

function mapStateToProps(state, ownProps) {
  return {
    currentUser: state.auth,
  };
}

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(Games);
