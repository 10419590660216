import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';

import SlideoutLayout from '../../layout/slideout/SlideoutLayout';
import UploadDisabledForm from './UploadDisabledForm';

import { getKeyProviders } from '../../../actions/keyProviderActions';
import {
  uploadDisabled,
  getDisableReasons,
} from '../../../actions/keyCodeActions';

import { useForm } from 'react-hook-form';
import PropTypes from 'prop-types';
import Loading from '../../common/Loading';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';

function UploadDisabled({
  done,
  cancel,
  keyProviders,
  getKeyProviders,
  disableReasons,
  getDisableReasons,
}) {
  const { t } = useTranslation();
  const {
    register,
    formState: { errors },
    handleSubmit,
    control,
    setValue,
    getValues,
  } = useForm();
  const [disabledFiles, setDisabledFiles] = useState([]); // State to track multiple files

  const addFiles = (files) => {
    setDisabledFiles((prevFiles) => [...prevFiles, ...files]); // Add new files to the existing list
  };
  const removeFile = (file) => {
    setDisabledFiles((prevFiles) =>
      prevFiles.filter((prevFile) => prevFile !== file)
    ); // Remove file from the list
  };

  const onSubmit = (data) => {
    // Map through all the files and call uploadDisabled for each
    Promise.all(disabledFiles.map((file) => uploadDisabled({ file, ...data })))
      .then(() => {
        toast.success(t('Disabled keys successfully uploaded'));
        done();
      })
      .catch((e) => {
        toast.error(e.message || t('Failed to upload disabled keys'));
      });
  };

  useEffect(() => {
    if (!keyProviders) {
      getKeyProviders();
    }
  }, [keyProviders, getKeyProviders]);

  useEffect(() => {
    if (!disableReasons) {
      getDisableReasons();
    }
  }, [disableReasons, getDisableReasons]);

  return keyProviders ? (
    <SlideoutLayout
      title={t('Upload disabled keys')}
      cancel={cancel}
      done={done}
    >
      <UploadDisabledForm
        keyProviders={keyProviders}
        disableReasons={disableReasons}
        register={register}
        errors={errors}
        control={control}
        cancel={cancel}
        submit={handleSubmit(onSubmit)}
        addFiles={addFiles}
        removeFile={removeFile}
        setValue={setValue}
        getValues={getValues}
        disabledFiles={disabledFiles}
      />
    </SlideoutLayout>
  ) : (
    <Loading></Loading>
  );
}

function mapStateToProps(state, ownProps) {
  return {
    keyProviders: state.keyProviders,
    disableReasons: state.disableReasons,
  };
}

const mapDispatchToProps = {
  getKeyProviders,
  getDisableReasons,
};

UploadDisabled.propTypes = {
  done: PropTypes.func.isRequired,
  cancel: PropTypes.func.isRequired,
  keyProviders: PropTypes.array,
  getKeyProviders: PropTypes.func,
  disableReasons: PropTypes.array,
  getDisableReasons: PropTypes.func,
};

export default connect(mapStateToProps, mapDispatchToProps)(UploadDisabled);
