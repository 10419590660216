import React from 'react';
import { connect } from 'react-redux';
import ActionBar from '../layout/ActionBar';
import KeysByCompanyByStatusList from './components/KeysByCompanyByStatusList';

function KeysByCompanyByStatus({ location, currentUser }) {
  return (
    <>
      <ActionBar></ActionBar>
      <KeysByCompanyByStatusList
        location={location}
        currentUser={currentUser}
      />
    </>
  );
}

function mapStateToProps(state, ownProps) {
  return {
    currentUser: state.auth,
  };
}

const mapDispatchToProps = {};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(KeysByCompanyByStatus);
