import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import SlidingPane from 'react-sliding-pane';
import ActionBar from '../layout/ActionBar';
import {
  getRequestById,
  getRequestByKeyRequestGroupId,
  downloadKeys,
  checkRequestGroupTransactionLimits,
  approveRequest,
  declineRequest,
  getAudit,
  addAudit,
  downloadAccountDetailsTemplate,
  downloadAccountDetails,
  changeStatus,
} from '../../actions/requestActions';
import Stats from './components/Stats';

import SlideoutChangeStatus from './slideout/ChangeStatus';
import SlideoutRequestDetail from './slideout/RequestDetails';
import RequestDetailsSignatures from './slideout/RequestDetailsSignatures';
import SlideoutReassign from './slideout/Reassign';
import SlideoutWithdraw from './slideout/Withdraw';
import SlideoutEditRequestValue from './slideout/EditRequestValue';
import SlideoutEditNumberOfKeys from './slideout/EditNumberOfKeys';
import SlideoutCopyRequest from './slideout/CopyRequest';

import ResponsiveActionBarButtons from '../common/ResponsiveActionBarButtons';

import RequestDetails from './components/RequestDetails';
import WarningMaxValue from './components/WarningMaxValue';
import WarningMaxKeys from './components/WarningMaxKeys';

import Games from './components/Games';

import Loading from '../common/Loading';
import UploadKeys from './slideout/UploadKeys';
import SteamRequest from './slideout/SteamRequest';
import { toast } from 'react-toastify';
import AuditButton from '../audit/components/AuditButton';
import { isPowerUser, userIs } from '../auth/authUtils';
import UploadAccountDetails from './slideout/UploadAccountDetails';
import useUploadNotificationsSingle from '../../hooks/useUploadNotificationsSingle';
import { addToGroup, removeFromGroup } from '../../actions/signalRActions';
import requestStatuses from '../../constants/requestStatuses';
import userTypes from '../../constants/userTypes';
import Button from '../common/Button';
import icons from '../../constants/icons';
import BulkUploadKeys from './slideout/BulkUploadKeys';
import DisableKeys from '../keys/slideout/DisableKeys';
import ReturnKeys from './slideout/ReturnKeys';
import { CanCopyRequest } from '../../util/permissions';
import { useTranslation } from 'react-i18next';
import DeclineReason from './slideout/DeclineReason';

function Request({
  id,
  history,
  currentUser,
  addToGroup,
  removeFromGroup,
  importTransactions,
  setCompleteRequestSlideoutOpen,
}) {
  const [requestGroup, setRequestGroup] = useState();
  const [requests, setRequests] = useState();
  const [unfilteredRequests, setUnfilteredRequests] = useState();
  const [searchFilter, setSearchFilter] = useState('');
  const [lastUpdate, setLastUpdate] = useState(new Date());
  const [transactionLimits, setTransactionLimits] = useState();
  const [processing, setProcessing] = useState(false);
  const [uploadAccountDetailsOpen, setUploadAccountDetailsOpen] =
    useState(false);
  const [showDeclineReason, setShowDeclineReason] = useState(false);
  const [declineReason, setDeclineReason] = useState();
  const { t } = useTranslation();

  const found = (foundTransactions) => {
    if (foundTransactions.length === 1) {
      const transaction = foundTransactions.slice(-1)[0];
      if (transaction.success === true) {
        setProcessing(false);
        toast.success(transaction.message);
        setUploadsInProgress((prevState) =>
          prevState.filter((id) => id !== transaction.id)
        );
        setLastUpdate(new Date());
      } else if (transaction.success === false) {
        setProcessing(false);
        toast.error(transaction.message);
        setUploadsInProgress((prevState) =>
          prevState.filter((id) => id !== transaction.id)
        );
        setLastUpdate(new Date());
      }
    }
  };
  const [uploadAccountDetail, setUploadAccountDetail] = useState();
  const [uploadsInProgress, setUploadsInProgress] =
    useUploadNotificationsSingle(
      'import',
      removeFromGroup,
      importTransactions,
      found
    );
  useEffect(() => {
    if (id) {
      // request group
      getRequestById(id)
        .then((d) => {
          setRequestGroup(d);

          // individual requests
          getRequestByKeyRequestGroupId(id)
            .then((d) => {
              setRequests(d);
              setUnfilteredRequests(d);
            })
            .catch((e) => {
              console.error(e);
              toast.error(t('No requests found'));
            });

          // check limits
          checkRequestGroupTransactionLimits(id)
            .then((d) => {
              setTransactionLimits(d);
            })
            .catch((e) => {
              console.error(e);
            });
        })
        .catch((e) => {
          console.error(e);
          history.push('/requests/all');
          toast.error(t('Request not found'));
        });
    }
  }, [id, history, lastUpdate, t]);

  useEffect(() => {
    if (unfilteredRequests) {
      // no search
      if (!searchFilter) {
        setRequests(unfilteredRequests);
        return;
      }
      // some search
      let lowerFilters = searchFilter.toLowerCase().split(' ');
      setRequests([
        ...unfilteredRequests.filter((x) =>
          lowerFilters.every(
            (f) =>
              x.gameTitle.toLowerCase().indexOf(f) !== -1 ||
              x.skuName.toLowerCase().indexOf(f) !== -1
          )
        ),
      ]);
    }
  }, [searchFilter, unfilteredRequests]);

  // slideout panel
  const [disableKeysSlideoutOpen, setDisableKeysSlideoutOpen] = useState(false);
  const [disableKeysSlideoutItem, setDisableKeysSlideoutItem] = useState();
  const handleDisableKeys = (e, request) => {
    e.cancelBubble = true;
    setDisableKeysSlideoutItem(request);
    setDisableKeysSlideoutOpen(true);
    return false;
  };

  const [reassignSlideoutOpen, setReassignSlideoutOpen] = useState(false);
  const [reassignSlideoutItem, setReassignSlideoutItem] = useState();
  const handleReassign = (e, request) => {
    e.cancelBubble = true;
    console.log(request);
    setReassignSlideoutItem(request);
    setReassignSlideoutOpen(true);
    return false;
  };

  const [changeStatusSlideoutOpen, setChangeStatusSlideoutOpen] =
    useState(false);
  const [changeStatusSlideoutItem, setChangeStatusSlideoutItem] = useState();
  const handleChangeStatus = (e, request) => {
    e.cancelBubble = true;
    setChangeStatusSlideoutItem(request);
    setChangeStatusSlideoutOpen(true);
  };

  const [withdrawSlideoutOpen, setWithdrawSlideoutOpen] = useState(false);
  const [withdrawSlideoutItem, setWithdrawSlideoutItem] = useState();
  const handleWithdrawRequest = (e, request) => {
    e.cancelBubble = true;
    setWithdrawSlideoutItem(request);
    setWithdrawSlideoutOpen(true);
  };

  const [editRequestValueSlideoutOpen, setEditRequestValueSlideoutOpen] =
    useState(false);
  const [editRequestValueSlideoutItem, setEditRequestValueSlideoutItem] =
    useState();
  const handleEditRequestValue = (e, request) => {
    e.cancelBubble = true;
    setEditRequestValueSlideoutOpen(true);
    setEditRequestValueSlideoutItem(request);
  };

  const [detailsSlideoutOpen, setDetailsSlideoutOpen] = useState(false);
  const [detailsSlideoutItem, setDetailsSlideoutItem] = useState();
  const [signaturesSlideoutOpen, setSignaturesSlideoutOpen] = useState(false);
  const [signaturesSlideoutItem, setSignaturesSlideoutItem] = useState();

  const handleShowDetails = (e, request) => {
    e.cancelBubble = true;
    setDetailsSlideoutItem(request);
    setDetailsSlideoutOpen(true);
  };

  let handleShowSignatures = (e, requestStep) => {
    setSignaturesSlideoutItem(requestStep);
    setSignaturesSlideoutOpen(true);
  };

  const [editNumberOfKeysSlideoutOpen, setEditNumberOfKeysSlideoutOpen] =
    useState(false);
  const [editNumberOfKeysSlideoutItem, setEditNumberOfKeysSlideoutItem] =
    useState();
  const handleEditNumberOfKeys = (e, request) => {
    e.cancelBubble = true;
    setEditNumberOfKeysSlideoutItem(request);
    setEditNumberOfKeysSlideoutOpen(true);
  };

  const handleDownloadKeys = (e, request) => {
    e.cancelBubble = true;
    setProcessing(true);
    downloadKeys(request.id)
      .then(() => {
        setLastUpdate(new Date());
        setProcessing(false);
      })
      .catch((e) => {
        toast.error(e.message || t('Failed to download keys'));
        setProcessing(false);
      });
  };

  const [uploadKeysSlideoutOpen, setUploadKeysSlideoutOpen] = useState(false);
  const [uploadKeysSlideoutItem, setUploadKeysSlideoutItem] = useState();
  const handleUploadKeys = (e, request) => {
    e.cancelBubble = true;
    setUploadKeysSlideoutItem(request);
    setUploadKeysSlideoutOpen(true);
  };

  const handleKeysOrdered = (e, request) => {
    e.cancelBubble = true;
    setProcessing(true);

    changeStatus(request.id, requestStatuses.KEYS_ORDERED, t('Keys ordered'))
      .then((d) => {
        toast.success(t('Request updated'));
        setLastUpdate(new Date());
        setProcessing(false);
      })
      .catch((e) => {
        toast.error(e.message || t('Failed to update request'));
        setProcessing(false);
      });
  };

  const [steamRequestSlideoutOpen, setSteamRequestSlideoutOpen] =
    useState(false);
  const [steamRequestSlideoutItem, setSteamRequestSlideoutItem] = useState();
  const handleSteamRequest = (e, item) => {
    e.cancelBubble = true;
    setSteamRequestSlideoutItem(item);
    setSteamRequestSlideoutOpen(true);
    return false;
  };

  const handleApproveAll = () => {
    let newArr = [...requests];
    newArr.forEach((x) => {
      if (x.currentUserCanSign) {
        x.action = 'approve';
      }
      setRequests(newArr);
    });
  };

  const handleDeclineAll = () => {
    let newArr = [...requests];
    newArr.forEach((x) => {
      if (x.currentUserCanSign) {
        x.action = 'decline';
      }
      setRequests(newArr);
    });
  };

  const handleApproveItem = (e, request) => {
    let newArr = [...requests];
    let index = newArr.findIndex((x) => x.id === request.id);
    if (index > -1) {
      newArr[index].action =
        request.action === 'approve' ? undefined : 'approve';
      setRequests(newArr);
    }
  };

  const handleDeclineItem = (e, request) => {
    let newArr = [...requests];
    let index = newArr.findIndex((x) => x.id === request.id);
    if (index > -1) {
      newArr[index].action =
        request.action === 'decline' ? undefined : 'decline';
      setRequests(newArr);
    }
  };

  const anyCanBeSigned = () => {
    if (!requests) {
      return false;
    }
    return requests.filter((request) => request.currentUserCanSign).length > 0;
  };

  const anyReadyToBeSigned = () => {
    if (!requests) {
      return false;
    }
    return requests.filter((request) => request.action).length > 0;
  };

  const [copyRequestSlideoutOpen, setCopyRequestSlideoutOpen] = useState(false);
  const handleCopy = (e) => {
    e.cancelBubble = true;
    setCopyRequestSlideoutOpen(true);
  };

  const processSelected = () => {
    if (!requests) {
      return false;
    }
    setProcessing(true);
    let actions = [];
    requests.forEach((x) => {
      if (x.action) {
        actions.push({ id: x.id, action: x.action });
      }
    });
    let finished = () => {
      toast.success(t('Processed successfully'));
      setLastUpdate(new Date());
      setProcessing(false);
      setDeclineReason();
    };
    let failed = (e) => {
      console.error(e);
      toast.error(e.message);
      setLastUpdate(new Date());
    };
    if (
      actions.filter((x) => x.action === 'decline').length > 0 &&
      !declineReason
    ) {
      setShowDeclineReason(true);
    } else {
      let processNext = () => {
        let x = actions.pop();
        if (x && x.action === 'approve') {
          // approve request
          approveRequest(x.id, null, null, null)
            .then((r) => {
              if (actions.length > 0) {
                processNext();
              } else {
                finished();
              }
            })
            .catch(failed);
        } else if (x && x.action === 'decline') {
          // approve request
          declineRequest(x.id, declineReason)
            .then((r) => {
              if (actions.length > 0) {
                processNext();
              } else {
                finished();
              }
            })
            .catch(failed);
        }
      };

      // kick it off
      if (actions.length > 0) {
        processNext();
      } else {
        setProcessing(false);
        toast.error(t('Nothing to process'));
      }
    }
  };

  useEffect(() => {
    if (declineReason) {
      setShowDeclineReason(false);
      processSelected();
    }
  }, [declineReason]);

  const handleUploadAccountDetails = (id, numberOfKeys) => {
    setUploadAccountDetail({ keyRequestId: id, numberOfKeys });
    setUploadAccountDetailsOpen(true);
  };
  const [bulkUploadOpen, setBulkUploadOpen] = useState(false);
  const handleBulkUploadKeys = () => {
    setBulkUploadOpen(true);
  };
  const anyReadyToBeUploaded = () => {
    if (!requests) {
      return false;
    }
    return requests.filter((request) => request.allowUploadKeys).length > 0;
  };
  const [returnKeysSlideoutOpen, setReturnKeysSlideoutOpen] = useState(false);
  const [returnKeysSlideoutItem, setReturnKeysSlideoutItem] = useState();
  const handleReturnKeys = (e, item) => {
    e.cancelBubble = true;
    setReturnKeysSlideoutOpen(true);
    setReturnKeysSlideoutItem(item);
    return false;
  };

  return requestGroup && requests && transactionLimits ? (
    <>
      <ActionBar
        breadcrumb={[
          {
            link: isPowerUser(currentUser)
              ? '/requests/all'
              : '/requests/made-by-me',
            label: t('Requests'),
          },
          { label: requestGroup.groupReference },
        ]}
      >
        <AuditButton id={id} getAudit={getAudit} addAudit={addAudit} />
        <ResponsiveActionBarButtons
          buttons={[
            userIs(currentUser, userTypes.UPLOADER) && (
              <Button
                className="btn btn-default ms-2 d-none d-md-inline-flex"
                onClick={handleBulkUploadKeys}
                isDisabled={!anyReadyToBeUploaded()}
                title={
                  !anyReadyToBeUploaded()
                    ? t('There are no requests at the status Pending Keys')
                    : t(
                        'You can upload multiple files and Alaska will allocate to the appropriate request in the group'
                      )
                }
                icon={icons.UPLOAD_KEYS}
                text={t('Upload keys')}
              />
            ),
            <Button
              key="REQUEST_AGAIN"
              isDisabled={
                !CanCopyRequest({
                  companyId: requestGroup.companyId,
                  requestedById: requestGroup.requestedById,
                  currentUser,
                })
              }
              className="btn btn-default ms-2 d-none d-md-inline-flex"
              onClick={handleCopy}
              title={t('Make a new request based on this request')}
              icon={icons.REQUEST_AGAIN}
              text={t('Request again')}
            />,
            anyCanBeSigned() && (
              <Button
                className="btn btn-default ms-2 d-none d-md-inline-flex"
                isDisabled={!anyReadyToBeSigned()}
                onClick={processSelected}
                icon={icons.PROCESS_SELECTION}
                text={t('Process selected')}
              />
            ),
          ]}
        ></ResponsiveActionBarButtons>
      </ActionBar>
      {isPowerUser(currentUser) &&
        anyCanBeSigned() &&
        transactionLimits &&
        transactionLimits.isOverTransactionLimit && (
          <WarningMaxValue
            transactionLimit={transactionLimits.transactionLimit}
            currentTransactionAmount={
              transactionLimits.valueOfAllPendingRequests
            }
            companyId={requestGroup.companyId}
            companyName={transactionLimits.companyName}
          ></WarningMaxValue>
        )}
      {isPowerUser(currentUser) &&
        anyCanBeSigned() &&
        transactionLimits &&
        transactionLimits.isOverGlobalTransactionLimit && (
          <WarningMaxKeys
            globalTransactionLimit={transactionLimits.globalTransactionLimit}
            currentGlobalTransactionAmount={
              transactionLimits.valueOfAllPendingRequestsWithStock
            }
            companyId={requestGroup.companyId}
            companyName={transactionLimits.companyName}
          ></WarningMaxKeys>
        )}
      <Stats
        numberOfKeys={requestGroup.numberOfKeys}
        currentRequestValue={requestGroup.currentRequestValue}
        keyWorkflowId={requestGroup.keyWorkflowId}
        keyWorkflowName={requestGroup.keyWorkflowName}
        keyWorkflowColourHex={requestGroup.keyWorkflowColourHex}
        companyName={requestGroup.companyName}
        companyId={requestGroup.companyId}
      ></Stats>
      <div className="col-sm-12">
        <RequestDetails request={requestGroup} onReassign={handleReassign} />
      </div>
      <div className="row">
        <div className="col-sm-12">
          <Games
            requests={requests}
            processing={processing}
            onApproveAll={handleApproveAll}
            onDeclineAll={handleDeclineAll}
            onApproveItem={handleApproveItem}
            onDeclineItem={handleDeclineItem}
            onWithdrawRequest={handleWithdrawRequest}
            onDownloadKeys={handleDownloadKeys}
            onUploadKeys={handleUploadKeys}
            onKeysOrdered={handleKeysOrdered}
            onSteamRequest={handleSteamRequest}
            onSetStatus={handleChangeStatus}
            onShowDetails={handleShowDetails}
            onEditRequestValue={handleEditRequestValue}
            onEditRequestKeyCount={handleEditNumberOfKeys}
            onDisableKeys={handleDisableKeys}
            onReassign={handleReassign}
            searchFilter={searchFilter}
            onSearchFilterChange={(filterText) => {
              setSearchFilter(filterText);
            }}
            endUserDetailsRequired={requestGroup.endUserDetailsRequired}
            handleUploadAccountDetails={handleUploadAccountDetails}
            handleReturnKeys={handleReturnKeys}
          ></Games>
        </div>
      </div>
      <SlidingPane
        isOpen={uploadAccountDetailsOpen}
        hideHeader={true}
        from="right"
        className="large-side-panel"
        onRequestClose={() => setUploadAccountDetailsOpen(false)}
      >
        <UploadAccountDetails
          id={uploadAccountDetail?.keyRequestId}
          numberOfKeys={uploadAccountDetail?.numberOfKeys}
          onUploadSuccess={() => {
            setLastUpdate(new Date());
          }}
          done={(accountDetails) => {
            setRequests(
              requests?.map((request) =>
                request.id === uploadAccountDetail?.keyRequestId
                  ? {
                      ...request,
                      accountDetails:
                        request.accountDetails + accountDetails.length,
                    }
                  : request
              )
            );

            setRequestGroup({
              ...requestGroup,
              numberOfAccountDetails:
                requestGroup.numberOfAccountDetails + accountDetails.length,
            });

            setUploadAccountDetailsOpen(false);
          }}
          cancel={() => setUploadAccountDetailsOpen(false)}
          downloadAccountDetailsTemplate={downloadAccountDetailsTemplate}
          downloadAccountDetails={() =>
            downloadAccountDetails(uploadAccountDetail?.keyRequestId)
          }
        />
      </SlidingPane>
      <SlidingPane
        isOpen={detailsSlideoutOpen}
        hideHeader={true}
        from="right"
        className="side-panel"
        onRequestClose={() => setDetailsSlideoutOpen(false)}
      >
        <>
          {detailsSlideoutItem && (
            <SlideoutRequestDetail
              requestGroupId={detailsSlideoutItem.requestGroupId}
              requestId={detailsSlideoutItem.id}
              history={history}
              done={(d) => {
                setDetailsSlideoutOpen(false);
              }}
              cancel={() => setDetailsSlideoutOpen(false)}
              onShowSignatures={handleShowSignatures}
            />
          )}
        </>
      </SlidingPane>
      <SlidingPane
        isOpen={signaturesSlideoutOpen}
        hideHeader={true}
        from="right"
        className="small-side-panel"
        onRequestClose={() => setSignaturesSlideoutOpen(false)}
      >
        <>
          {signaturesSlideoutItem && (
            <RequestDetailsSignatures
              done={(d) => {
                setSignaturesSlideoutOpen(false);
              }}
              cancel={() => setSignaturesSlideoutOpen(false)}
              requestStep={signaturesSlideoutItem}
            />
          )}
        </>
      </SlidingPane>
      <SlidingPane
        isOpen={withdrawSlideoutOpen}
        hideHeader={true}
        from="right"
        className="small-side-panel"
        onRequestClose={() => setWithdrawSlideoutOpen(false)}
      >
        <>
          {withdrawSlideoutItem && (
            <SlideoutWithdraw
              done={(d) => {
                setLastUpdate(new Date());
                setWithdrawSlideoutOpen(false);
              }}
              cancel={() => setWithdrawSlideoutOpen(false)}
              id={withdrawSlideoutItem.id}
              history={history}
            />
          )}
        </>
      </SlidingPane>
      <SlidingPane
        isOpen={editRequestValueSlideoutOpen}
        hideHeader={true}
        from="right"
        className="small-side-panel"
        onRequestClose={() => setEditRequestValueSlideoutOpen(false)}
      >
        <>
          {editRequestValueSlideoutItem && (
            <SlideoutEditRequestValue
              done={(d) => {
                setLastUpdate(new Date());
                setEditRequestValueSlideoutOpen(false);
              }}
              cancel={() => setEditRequestValueSlideoutOpen(false)}
              request={editRequestValueSlideoutItem}
              history={history}
            />
          )}
        </>
      </SlidingPane>
      <SlidingPane
        isOpen={editNumberOfKeysSlideoutOpen}
        hideHeader={true}
        from="right"
        className="small-side-panel"
        onRequestClose={() => setEditNumberOfKeysSlideoutOpen(false)}
      >
        <>
          {editNumberOfKeysSlideoutItem && (
            <SlideoutEditNumberOfKeys
              done={(d) => {
                setLastUpdate(new Date());
                setEditNumberOfKeysSlideoutOpen(false);
              }}
              cancel={() => setEditNumberOfKeysSlideoutOpen(false)}
              request={editNumberOfKeysSlideoutItem}
              history={history}
            />
          )}
        </>
      </SlidingPane>

      <SlidingPane
        isOpen={reassignSlideoutOpen}
        hideHeader={true}
        from="right"
        className="large-side-panel"
        onRequestClose={() => setReassignSlideoutOpen(false)}
      >
        <>
          {reassignSlideoutItem && (
            <SlideoutReassign
              done={(d) => {
                // find the request with the same id and update it
                setRequests(
                  requests.map((request) =>
                    request.id === reassignSlideoutItem.id
                      ? {
                          ...request,
                          assignedToUserProfileFullName:
                            d.assignedToUserProfileFullName,
                          assignedToUserProfileId: d.assignedToUserProfileId,
                          assignmentDate: d.assignmentDate,
                        }
                      : request
                  )
                );
                setReassignSlideoutOpen(false);
              }}
              cancel={() => setReassignSlideoutOpen(false)}
              request={reassignSlideoutItem}
              history={history}
            />
          )}
        </>
      </SlidingPane>

      <SlidingPane
        isOpen={disableKeysSlideoutOpen}
        hideHeader={true}
        from="right"
        className="side-panel"
        onRequestClose={() => setDisableKeysSlideoutOpen(false)}
      >
        <>
          {disableKeysSlideoutItem && (
            <DisableKeys
              title={
                t('Disable keys for {{reference}}', {
                  referece: disableKeysSlideoutItem.reference,
                }) || t('Request')
              }
              targetKeyRequestId={disableKeysSlideoutItem.id}
              done={(d) => {
                setDisableKeysSlideoutOpen(false);
                setLastUpdate(new Date());
              }}
              cancel={() => setDisableKeysSlideoutOpen(false)}
              // defaultReason={`Disable keys for "${disableKeysSlideoutItem.reference}" request`}
              // was this meant to be?
              // defaultReason={`Disable keys for "${disableKeysSlideoutItem.reference}` || 'request' like line 735}
              defaultReason={t('Disable keys for "{{reference}}" request', {
                reference: disableKeysSlideoutItem.reference,
              })}
            />
          )}
        </>
      </SlidingPane>

      <SlidingPane
        isOpen={uploadKeysSlideoutOpen}
        hideHeader={true}
        from="right"
        className="large-side-panel"
        onRequestClose={() => setUploadKeysSlideoutOpen(false)}
      >
        <>
          {uploadKeysSlideoutItem && (
            <UploadKeys
              done={(d) => {
                if (!uploadsInProgress.includes(d.id)) {
                  addToGroup(`import:${d.id}`);
                  setUploadsInProgress((prevState) =>
                    prevState.includes(d.id) ? prevState : [...prevState, d.id]
                  );
                  setProcessing(true);
                }
                setLastUpdate(new Date());
                setUploadKeysSlideoutOpen(false);
              }}
              cancel={() => setUploadKeysSlideoutOpen(false)}
              requestId={uploadKeysSlideoutItem.id}
              gameName={uploadKeysSlideoutItem.gameTitle}
              skuName={uploadKeysSlideoutItem.skuName}
              keyProviderName={uploadKeysSlideoutItem.keyProviderName}
              numberOfKeys={uploadKeysSlideoutItem.numberOfKeys}
            ></UploadKeys>
          )}
        </>
      </SlidingPane>

      <SlidingPane
        isOpen={steamRequestSlideoutOpen}
        hideHeader={true}
        from="right"
        className="large-side-panel"
        onRequestClose={() => setSteamRequestSlideoutOpen(false)}
      >
        <>
          {steamRequestSlideoutItem && (
            <SteamRequest
              done={(d) => {
                setSteamRequestSlideoutOpen(false);
                setLastUpdate(new Date());
              }}
              cancel={() => setSteamRequestSlideoutOpen(false)}
              keyRequestId={steamRequestSlideoutItem.id}
              keyWorkflowId={steamRequestSlideoutItem.keyWorkflowId}
              keyPoolName={steamRequestSlideoutItem.keyWorkflowName}
              gameName={steamRequestSlideoutItem.gameTitle}
              gameSkuId={steamRequestSlideoutItem.skuId}
              skuName={steamRequestSlideoutItem.skuName}
              packageTypeId={steamRequestSlideoutItem.packageTypeId}
              customerName={steamRequestSlideoutItem.keyProviderReference}
              numberOfKeys={steamRequestSlideoutItem.numberOfKeys}
            ></SteamRequest>
          )}
        </>
      </SlidingPane>

      <SlidingPane
        isOpen={changeStatusSlideoutOpen}
        hideHeader={true}
        from="right"
        className="small-side-panel"
        onRequestClose={() => setChangeStatusSlideoutOpen(false)}
      >
        <>
          {changeStatusSlideoutItem && (
            <SlideoutChangeStatus
              done={(d) => {
                setChangeStatusSlideoutOpen(false);
                setLastUpdate(new Date());
              }}
              cancel={() => setChangeStatusSlideoutOpen(false)}
              request={changeStatusSlideoutItem}
              history={history}
            />
          )}
        </>
      </SlidingPane>

      <SlidingPane
        isOpen={copyRequestSlideoutOpen}
        hideHeader={true}
        from="right"
        className="xlarge-side-panel"
        onRequestClose={() => setCopyRequestSlideoutOpen(false)}
      >
        <>
          {requestGroup && requests && (
            <SlideoutCopyRequest
              done={(d) => {
                setCopyRequestSlideoutOpen(false);
              }}
              cancel={() => setCopyRequestSlideoutOpen(false)}
              requestGroup={requestGroup}
              requests={requests}
              history={history}
              currentUser={currentUser}
              setCompleteRequestSlideoutOpen={setCompleteRequestSlideoutOpen}
            />
          )}
        </>
      </SlidingPane>

      <SlidingPane
        isOpen={bulkUploadOpen}
        hideHeader={true}
        from="right"
        className="large-side-panel"
        onRequestClose={() => setBulkUploadOpen(false)}
      >
        <BulkUploadKeys
          done={(d) => {
            for (const importTransactionId of d) {
              if (!uploadsInProgress.includes(importTransactionId)) {
                addToGroup(`import:${importTransactionId}`);
                setUploadsInProgress((prevState) =>
                  prevState.includes(importTransactionId)
                    ? prevState
                    : [...prevState, importTransactionId]
                );
                setProcessing(true);
              }
            }
            setLastUpdate(new Date());
            setBulkUploadOpen(false);
          }}
          cancel={() => setBulkUploadOpen(false)}
          keyRequestGroupId={id}
          requestGroupReference={requestGroup?.groupReference}
        ></BulkUploadKeys>
      </SlidingPane>
      <SlidingPane
        isOpen={returnKeysSlideoutOpen}
        hideHeader={true}
        from="right"
        className="side-panel"
        onRequestClose={() => setReturnKeysSlideoutOpen(false)}
      >
        <ReturnKeys
          request={returnKeysSlideoutItem}
          done={() => {
            setReturnKeysSlideoutOpen(false);
            setLastUpdate(new Date());
          }}
          cancel={() => setReturnKeysSlideoutOpen(false)}
        ></ReturnKeys>
      </SlidingPane>

      <SlidingPane
        isOpen={showDeclineReason}
        hideHeader={true}
        from="right"
        className="side-panel"
        onRequestClose={() => setShowDeclineReason(false)}
      >
        <DeclineReason
          done={() => setShowDeclineReason(false)}
          cancel={() => setShowDeclineReason(false)}
          onSubmit={(data) => {
            setDeclineReason(data.reason);
          }}
        />
      </SlidingPane>
    </>
  ) : (
    <Loading></Loading>
  );
}

function mapStateToProps(state, ownProps) {
  return {
    id: ownProps.match.params.id,
    currentUser: state.auth,
    importTransactions: state.importTransactions,
  };
}

const mapDispatchToProps = {
  addToGroup,
  removeFromGroup,
};

export default connect(mapStateToProps, mapDispatchToProps)(Request);
