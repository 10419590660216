import React from 'react';
import Button from '../../common/Button';
import { useTranslation } from 'react-i18next';
import UserLink from '../../common/links/UserLink';

const AssignedTo = ({ request, processing, onReassign }) => {
  const { t } = useTranslation();
  return (
    <div className="col-6">
      <label className="col-form-label">{t('Assigned to')}</label>
      <div className="flex ps-0">
        <div className="form-control-static px-3 width-100 flex align-items-center h-100">
          {request.assignedToUserProfileFullName ? (
            <UserLink
              id={request.assignedToUserProfileId}
              name={request.assignedToUserProfileFullName}
            />
          ) : (
            t('Unassigned')
          )}
        </div>
        <Button
          isDisabled={processing}
          title={processing ? t('Processing') : t('Edit')}
          onClick={(e) => onReassign(e, request)}
          className="btn btn-default float-left r-corners-right"
          text={t('Edit')}
        />
      </div>
    </div>
  );
};
export default AssignedTo;
